import { FETCH_ADDRESSES_MAP, FETCH_ADDRESSES_ATTEMP_MAP } from '../actions/types'
import axios from 'axios';
export const fetchaddressesmap = ({ catId }) => {

    return (dispatch) => {
        dispatch({ type: FETCH_ADDRESSES_ATTEMP_MAP });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/fetchAddressCats.php', { catId })
           .then((resp) => dispatch({ type: FETCH_ADDRESSES_MAP, data: resp.data }))
             /*  .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
