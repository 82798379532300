
import { SEARCH_ATTEMPT_EDITOR, SEARCH_SUCCESS_EDITOR, SEARCH_FAILED_EDITOR , RESET_SEARCH_EDITOR} from '../../../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false,
    success: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_ATTEMPT_EDITOR:
            return { ...state, loading: true , success: false}
        case SEARCH_SUCCESS_EDITOR:
            return { ...state, data: action.data,loading: false,  success: action.success  }
        case SEARCH_FAILED_EDITOR:
             return { ...state,data: action.data, loading: false, success: action.success }
        case RESET_SEARCH_EDITOR:
             return { ...state, loading: false , success: false, data: [] }
        default:
            return state;
    }
}