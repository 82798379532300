import { FETCH_EDIT_USER } from '../types'
import axios from 'axios';
export const fetchedituser = ({ userEditId })=> {

    return (dispatch) => {
        dispatch({ type: FETCH_EDIT_USER });
        //call the backend
        console.log(userEditId)
            axios.post('https://zliten.onwan.ly/API/users/fetchEditUser.php', { userEditId })
             .then((resp) => dispatch({ type: FETCH_EDIT_USER, data: resp.data }))
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

