import { SEARCH_ADDRESS_ATTEMPT_MAP, SEARCH_ADDRESS_SUCCESS_MAP, SEARCH_ADDRESS_FAILED_MAP } from '../actions/types'
import axios from 'axios';
export const searchmap = ({ input }) => {

    return (dispatch) => {
        dispatch({ type: SEARCH_ADDRESS_ATTEMPT_MAP });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/fetchAddress.php', { input })
               .then((resp) => handleResponse(dispatch, resp.data)) 
           /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
    if (data === undefined) {
        onFailed(dispatch, data)
        console.log('Failed')
        console.log(data)
    } else {
        onSuccess(dispatch, data )
        console.log('Success')
        console.log(data)
    }
}
const onSuccess = (dispatch, data) => {
    dispatch({ type: SEARCH_ADDRESS_SUCCESS_MAP, data: data })
}

const onFailed = (dispatch,  data) => {
    dispatch({ type: SEARCH_ADDRESS_FAILED_MAP, data: data })
}