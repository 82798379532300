import { EDIT_CAT_ATTEMPT, EDIT_CAT_SUCCESS, EDIT_CAT_FAILED } from '../types'
import axios from 'axios';
export const editcat = ({ catEditId, nameEdit, userId, viewEdit}) => {

    return (dispatch) => {
        dispatch({ type: EDIT_CAT_ATTEMPT });
        //call the backend
        console.log(catEditId)
        console.log(nameEdit)
        console.log(userId)
            axios.post('https://zliten.onwan.ly/API/categories/editCat.php', { catEditId, nameEdit, userId, viewEdit})
             .then((resp) => handleResponse(dispatch, resp.data)) 
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message, data.success)
        console.log('Failed')
        console.log(data)
    } else {
        onSuccess(dispatch, data.message, data.success )
        console.log('Success')
        console.log(data)
    }
}
const onSuccess = (dispatch, message, success) => {
    dispatch({ type: EDIT_CAT_SUCCESS, message: message , success: success })
}

const onFailed = (dispatch,  message, success) => {
    dispatch({ type: EDIT_CAT_FAILED,  message: message , success: success  })
}