import { EDIT_CAT_ATTEMPT, EDIT_CAT_SUCCESS, EDIT_CAT_FAILED , RESET_EDIT_CAT} from '../../actions/types'
const INITIAL_STATE = {
    loading: false,
    message: null ,
    success: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EDIT_CAT_ATTEMPT:
            return { ...state, loading: true , success: false}
        case EDIT_CAT_SUCCESS:
            return { ...state, loading: false, message: action.message, success: action.success  }
        case EDIT_CAT_FAILED:
             return { ...state, loading: false, message: action.message, success: action.success }
        case RESET_EDIT_CAT:
             return { ...state, loading: false , success: false, message: null }
        default:
            return state;
    }
}