import { FETCH_EDIT_CAT } from '../types'
import axios from 'axios';
export const fetcheditcat = ({ catEditId })=> {

    return (dispatch) => {
        dispatch({ type: FETCH_EDIT_CAT });
        //call the backend
        console.log(catEditId)
            axios.post('https://zliten.onwan.ly/API/categories/fetchEditCat.php', { catEditId })
              .then((resp) => dispatch({ type: FETCH_EDIT_CAT, data: resp.data }))
            /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

