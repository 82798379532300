import { MAIN_DATA } from './types'
import axios from 'axios';
export const maindata = ()=> {

    return (dispatch) => {
        dispatch({ type: MAIN_DATA });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/mainData.php')
             .then((resp) => dispatch({ type: MAIN_DATA, data: resp.data }))
             /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

