import { SEARCH_ADDRESS_ATTEMPT_MAP, SEARCH_ADDRESS_SUCCESS_MAP, SEARCH_ADDRESS_FAILED_MAP } from '../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false,
    
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_ADDRESS_ATTEMPT_MAP:
            return { ...state, loading: true }
        case SEARCH_ADDRESS_SUCCESS_MAP:
            return { ...state, loading: false,  data: action.data  }
        case SEARCH_ADDRESS_FAILED_MAP:
             return { ...state, loading: false, data: action.data }
        default:
            return state;
    }
}