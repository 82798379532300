import { FETCH_ADDRESSES, FETCH_ADDRESSES_ATTEMPT } from '../types'
import axios from 'axios';
export const fetchaddresses = () => {

    return (dispatch) => {
        dispatch({ type: FETCH_ADDRESSES_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/fetchAddress.php')
             .then((resp) => dispatch({ type: FETCH_ADDRESSES, data: resp.data }))
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
