import { SEND_ATTEMPT, SEND_SUCCESS, SEND_FAILED } from './types'
import axios from 'axios';
export const auth = ({ username, password }) => {

    return (dispatch) => {
        dispatch({ type: SEND_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/Auth.php', { username , password })
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message)
    } else {
        onSuccess(dispatch, data.id ,data.name, data.admin, data.default_cat, data.username)

    }
}
const onSuccess = (dispatch, id, name , admin , default_cat, username) => {
    dispatch({ type: SEND_SUCCESS, id: id, name: name, admin: admin , default_cat: default_cat, username: username})

}

const onFailed = (dispatch, error) => {
    dispatch({ type: SEND_FAILED, error })
}