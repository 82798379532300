import { DELETE_USER_SUCCESS, DELETE_USER_FAILED,DELETE_USER_ATTEMPT, RESET_DELETE_USER} from '../../actions/types'
const INITIAL_STATE = {
    deleteMessage: null,
    deleteLoading: false,
    deleteSuccess: false,
    reset: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_USER_ATTEMPT:
            return { ...state, deleteMessage: action.message, deleteLoading: true, deleteSuccess: false}
        case DELETE_USER_SUCCESS:
            return { ...state, deleteMessage: action.message,  deleteLoading: false , deleteSuccess: true}
        case DELETE_USER_FAILED:
            return { ...state, deleteMessage: action.message , deleteLoading: false , deleteSuccess: true}
        case RESET_DELETE_USER:
            return { ...state, deleteMessage: null, deleteLoading: false, deleteSuccess: false, reset: true }
       default:
            return state;
    }
}