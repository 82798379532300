import { CREATEUSER_ATTEMPT, CREATEUSER_SUCCESS, CREATEUSER_FAILED } from '../types'
import axios from 'axios';
export const createuser = ({ name, username, password, mobile, admin, catId ,permId }) => {

    return (dispatch) => {
        dispatch({ type: CREATEUSER_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/users/createUser.php', { name, username, password, mobile, admin , catId ,permId})
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message , data.admin)
        console.log('failed')
        console.log(data.message)
        console.log(data.admin)
    } else {
        onSuccess(dispatch, data.message  , data.admin )
        console.log('success')
        console.log(data.message)
        console.log(data.admin)
        

        

    }
}
const onSuccess = (dispatch, message) => {
    dispatch({ type: CREATEUSER_SUCCESS, message: message })

}

const onFailed = (dispatch, message) => {
    dispatch({ type: CREATEUSER_FAILED, message: message })
}