
import { DELETE_USER_ATTEMPT, DELETE_USER_SUCCESS, DELETE_USER_FAILED } from '../types'
import axios from 'axios';
export const deleteuser = ({ userDeleteId })=> {
    return (dispatch) => {
        dispatch({ type: DELETE_USER_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/users/deleteUser.php', { userDeleteId })
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message )
        console.log('failed')
        console.log(data.message)
    } else {
        onSuccess(dispatch, data.message)
        console.log('success')
        console.log(data.message)
    }
}
const onSuccess = (dispatch, message) => {
    dispatch({ type: DELETE_USER_SUCCESS, message: message })

}
const onFailed = (dispatch, message) => {
    dispatch({ type: DELETE_USER_FAILED, message: message })
}