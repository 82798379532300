import { FETCH_ADDRESSES_EDITOR , FETCH_ADDRESSES_ATTEMPT_EDITOR} from '../../../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADDRESSES_ATTEMPT_EDITOR:
            return { ...state, loading: true }
        case FETCH_ADDRESSES_EDITOR:
            return { ...state, data: action.data, loading: false }
       default:
            return state;
    }
}