import { FETCH_EDIT_CAT} from '../../actions/types'
const INITIAL_STATE = {
    data: [],
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_EDIT_CAT:
            return { ...state, data: action.data }
       default:
            return state;
    }
}