import React , {Component ,  useState } from 'react';
import { makeStyles,fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Hidden from '@material-ui/core/Hidden';
import grey from '@material-ui/core/colors/grey';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import COLOR from './color';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Room from '@material-ui/icons/Room';
import AddLocation from '@material-ui/icons/AddLocation';
import MapRounded from '@material-ui/icons/MapRounded';
import AssignmentRounded from '@material-ui/icons/AssignmentRounded';
import PeopleRounded from '@material-ui/icons/PeopleRounded';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Redirect, Link } from 'react-router-dom';

import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import Main from './Main';
import Map from './Mapp';
import Address from './adresses';
import AddAddress from './addAddress';
import Cat from './cat';
import Users from './users'
import cookie from 'react-cookies';

const theme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: grey,
  },
});

const drawerWidth =  250;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: COLOR.primaryG,
    marginRight: drawerWidth,
    [theme.breakpoints.up('sm')]: {
          zIndex: theme.zIndex.drawer + 1,

    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    width:'100%', textAlign:'center', fontFamily: 'Cairo', fontSize: 15,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
  },
  list: {
fontFamily: 'Cairo', padding: 6
  },
  
  nested: {
    paddingRight: theme.spacing(4),
    padding: 10, borderWidth: 4, borderRadius: 20
  },
  toolbar: theme.mixins.toolbar,
  popup: {
    display: 'flex',
  },
  nav : {
    width:'100%'
  },
  popupDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupContent: {
    flex: '1 0 auto',
    
  },
  mainCard: {
    display: 'flex',
    width:'100%'
  },
  mainInfo : {
    [theme.breakpoints.up('sm')]: {
      padding:10,
    display: 'flex',
    },
    padding:10,
    margin: 0
  },
  mainDetails: {
    display: 'flex',
    flexDirection: 'column',
    width:'70%'

  },
  mainContent: {
    paddingRight: theme.spacing(3),
    flex: '1 0 auto',
    backgroundColor: COLOR.light,
    
    
  },
  mainCardText: {
    fontFamily: 'Cairo',fontSize:15,
    [theme.breakpoints.up('sm')]: {
      fontSize:20
    },
    
  },
  mainCardNumber: {
    background:COLOR.primaryG,
    fontFamily: 'Cairo',
    fontSize:25,
    textAlign: 'center',
    width: '30%',
    paddingTop:10,
    [theme.breakpoints.up('sm')]: {
      fontSize:35
    },
    
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width:'33%'
    },
    flexDirection: 'row',
    margin:10,
  },
  info : {
    [theme.breakpoints.up('sm')]: {
      padding:20,
    display: 'flex',
    flexDirection: 'row',
    },
    padding:20,
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  cardContent: {
    paddingRight: theme.spacing(3),
    flex: '1 0 auto',
    backgroundColor: COLOR.light
    
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });



class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      lat: 32.367875,
      lng: 15.093149,
      mobileOpen: false,
      open: false,
      openuser: false,
      opencat: false,
      anchorEl: null,

    };
  }

   handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  logout = () => {
    console.log('hey')
    cookie.remove('admin', { path: '/' })
    window.location.assign("/");

  };

  render() {
    const { classes } = this.props;

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    console.log(cookie.load('admin'))
  
    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <List className={classes.list} >
            <ListItem button style={{padding: 10, borderWidth: 4, borderRadius: 20 , }} component={Link} to={'/admin'} onClick={()=> this.setState({mobileOpen:false})}>
              <ListItemIcon style={{color: '#c7a05a'}}> <Home /> </ListItemIcon>
              <ListItemText classes={{primary:classes.list}}>
              
                الرئيسية
              </ListItemText>
            </ListItem>
            <ListItem button style={{padding: 10, borderWidth: 4, borderRadius: 20 , }} component={Link} to={'/map'} onClick={()=> this.setState({mobileOpen:false})}>
            <ListItemIcon style={{color: '#c7a05a'}}>
              <MapRounded />
            </ListItemIcon>
            <ListItemText  classes={{primary:classes.list}}>
               الخريـطة
            </ListItemText>
          </ListItem>
            <ListItem button onClick={() => this.setState({ open: !this.state.open})} button style={{padding: 10, borderWidth: 4, borderRadius: 20}}  >
        <ListItemIcon style={{color: '#c7a05a'}}>
          <Room />
        </ListItemIcon >
        <ListItemText classes={{primary:classes.list}}>
        العناوين
        </ListItemText>
        {this.state.open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={this.state.open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding onClick={()=> this.setState({mobileOpen:false})}>
        <ListItem button className={classes.nested} component={Link} to={'/addresses'} >
            <ListItemIcon style={{color: '#c7a05a'}}>
              <Room />
            </ListItemIcon>
            <ListItemText  classes={{primary:classes.list}}>
            عرض العناوين
            </ListItemText>
          </ListItem>

        <ListItem button className={classes.nested}  component={Link} to={'/addaddress'}>
            <ListItemIcon style={{color: '#c7a05a'}}>
              <AddLocation />
            </ListItemIcon>
            <ListItemText  classes={{primary:classes.list}}>
               إضـافة عنوان
            </ListItemText>
          </ListItem>

         
          
        </List>
      </Collapse>
      <ListItem button style={{padding: 10, borderWidth: 4, borderRadius: 20 , }} component={Link} to={'/categories'} onClick={()=> this.setState({mobileOpen:false})}>
            <ListItemIcon style={{color: '#c7a05a'}}>
              <AssignmentRounded />
            </ListItemIcon>
            <ListItemText  classes={{primary:classes.list}}>
             الفئـات
            </ListItemText>
          </ListItem>
          <ListItem button style={{padding: 10, borderWidth: 4, borderRadius: 20 , }} component={Link} to={'/users'} onClick={()=> this.setState({mobileOpen:false})}>
            <ListItemIcon style={{color: '#c7a05a'}}>
              <PeopleRounded />
            </ListItemIcon>
            <ListItemText  classes={{primary:classes.list}}>
            المستخدمين
            </ListItemText>
          </ListItem>
          

        </List>
      </div>
    );
    if (cookie.load('admin') == 0) {
      return <Redirect to='/user' />
    } 
    if (cookie.load('admin') == 2) {
      return <Redirect to='/editor' />
    } 
    if (cookie.load('admin') == null) {
      return <Redirect to='/' />
    } 
    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
      <Router>
      <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation="0">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => this.setState({ mobileOpen :  !this.state.mobileOpen })}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            منظومة العنوان - المجلس البلدي زليتن
          </Typography>
          <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={this.handleClick}
            >
              <AccountCircle />
            </IconButton>
            <Popover
        id={id}
        open={open}
        anchorEl={this.state.anchorEl}
        onClose={() => this.setState({
          anchorEl : null
        })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className={classes.popup}>
      <div className={classes.popupDetails}>
        <CardContent className={classes.popupContent}>
          <Typography component="h5" variant="h5" style={{fontFamily: 'Cairo',}}>
            {cookie.load('name')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{fontFamily: 'Cairo',}}>
            مدير النظام
          </Typography>
        </CardContent>
        <div className={classes.controls}>
        <Button variant="contained" style={{fontFamily: 'Cairo' , fontSize : 15 , width:200 , background : COLOR.secondary , color: '#000' ,
  '&:hover': {
    background: COLOR.secondary,
 }}} 
 onClick={this.logout.bind(this)}
 >
        تسجيـل الخروج
      </Button>
        </div>
      </div>
    </Card>
      </Popover>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.state.mobileOpen}
            onClose={() => this.setState({ mobileOpen :  !this.state.mobileOpen })}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.nav} > 
        <Switch>
          <Route exact path="/admin">
            <Main />
          </Route>
          <Route  path="/map">
            <Map />
          </Route>
          <Route  path="/addresses">
            <Address />
          </Route>
          <Route  path="/addaddress">
            <AddAddress />
          </Route>
          <Route  path="/categories">
            <Cat />
          </Route>
          <Route  path="/users">
            <Users />
          </Route>
        </Switch>
        </div>
      </main>
    </div>
    </Router>
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}
export default (withStyles(styles)(Admin))
/* export default GoogleApiWrapper({
  apiKey: ('AIzaSyDFdoN0NF6ue3dLmPRE8Id8XMVCZ1Xr434')
})(withStyles(styles)(Admin)) */