import { CREATE_ADDRESS_ATTEMPT, CREATE_ADDRESS_SUCCESS, CREATE_ADDRESS_FAILED } from '../../actions/types'
const INITIAL_STATE = {
    loading: false,
    message: '' ,
    success: false,
    address:null
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_ADDRESS_ATTEMPT:
            return { ...state, loading: true ,message: 'waiting', success: false}
        case CREATE_ADDRESS_SUCCESS:
            return { ...state, loading: false,message: 'success', success: action.success, address: action.address  }
        case CREATE_ADDRESS_FAILED:
             return { ...state, loading: false, message: 'failed', success: action.success }
        default:
            return state;
    }
}