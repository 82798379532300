import { FETCH_EDIT_ADDRESS} from '../types'
import axios from 'axios';
export const fetcheditaddress = ({ addressIdEdit })=> {

    return (dispatch) => {
        dispatch({ type: FETCH_EDIT_ADDRESS });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/fetchEditAddress.php', { addressIdEdit })
              .then((resp) => dispatch({ type: FETCH_EDIT_ADDRESS, data: resp.data }))
            /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

