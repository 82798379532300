import { FETCH_CATEGORIES_ATTEMPT_MAP, FETCH_CATEGORIES_MAP} from '../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_ATTEMPT_MAP:
            return { ...state, loading: true }
        case FETCH_CATEGORIES_MAP:
            return { ...state, data: action.data , loading: false}
       default:
            return state;
    }
}