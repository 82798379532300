import { MAIN_DATA} from '../actions/types'
const INITIAL_STATE = {
    data: [],

}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAIN_DATA:
            return { ...state, data: action.data }
            default:
            return state;
    }
}