import { SEARCH_ATTEMPT_EDITOR, SEARCH_SUCCESS_EDITOR, SEARCH_FAILED_EDITOR} from '../../types'
import axios from 'axios';
export const searchaddresseditor = ({ mobile  ,firstDate, secondDate, userId })=> {

    return (dispatch) => {
        dispatch({ type: SEARCH_ATTEMPT_EDITOR });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/editor/search.php', { mobile ,  firstDate, secondDate , userId})
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))  */
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
    if (data === null) {
        onFailed(dispatch, data)
    } else {
        onSuccess(dispatch, data )
    }
}
const onSuccess = (dispatch, data) => {
    dispatch({ type: SEARCH_SUCCESS_EDITOR, data: data })
}

const onFailed = (dispatch, data) => {
    dispatch({ type: SEARCH_FAILED_EDITOR, data: data })
}