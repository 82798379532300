import React , {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme ,withStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Button from '@material-ui/core/Button';
import COLOR from './color';
import './App.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Grow from '@material-ui/core/Grow';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddBox from '@material-ui/icons/AddBox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createuser , fetchusers , fetchcategories , fetchpermissions, resetusers , deleteuser, resetdeleteuser , fetchedituser ,edituser, resetedituser} from './redux/actions';
import { connect } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { tsPropertySignature } from '@babel/types';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const theme = createMuiTheme({
    direction: "rtl",
    palette: {
        primary: {
            main: '#000'
          },

    },
  });

const styles = theme => ({
    root: {
      overflowX: "auto",
      width: '353px',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
        
    },
    pagination: {
     display: 'none',
     fontFamily:'Cairo',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        display: 'flex',
        fontFamily:'Cairo',
      },

        
    },
    pageControls : {
      width: 500,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
      input: {
        fontFamily: 'Cairo',
        fontSize: 18,
        width:150
        
      },
      iconButton: {
        background: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
      },
       editIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
      },
      deleteIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
        margin:5
      },
      addIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
        fontFamily: 'Cairo',
        fontSize:16,
        
      },
      title: {
        fontFamily: 'Cairo',
        fontSize : 25,
        textAlign: 'center'
        
      },
      button: {
        background: COLOR.secondary,
  margin: '5px',
  fontFamily: 'Cairo',
  fontSize : 16,
  color: '#000',
  '&:hover': {
    background: COLOR.secondary,
 },
      },
  });

  
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });



class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
        page: 0,
        rowsPerPage: 5,
        open: false,
        name: null,
        username: null,
        password: null,
        mobile: null,
        nameEdit: null,
        usernameEdit: null,
        passwordEdit: null,
        mobileEdit: null,
        userEditId: null,
        catIdEdit: null,
        userDeleteId: null,
        openDelete: false,
        edit: false,
        editSuccess: false,
        moreName: null,
        moreUsername: null,
        morePassword: null,
        moreAdmin: '',
        openMore: false,
        moreMobile: false,
        default_cat: null,
        catId: 0,
        permId: 0,
        ...props        
    };
  }
   /* ------------------------------ADD FUNCTIONS------------------------------ */

  handleName = event => {
    this.setState({
      name : event.target.value
    })
  };
  handleUsername = event => {
    this.setState({
      username : event.target.value
    })
  };
  handlePassword = event => {
    this.setState({
      password : event.target.value
    })
  };
  handleMobile = event => {
    this.setState({
      mobile : event.target.value
    })
  };

 /* ------------------------------EDIT FUNCTIONS------------------------------ */


 handleNameEdit = event => {
  this.setState({
    nameEdit : event.target.value
  })
};
handleUsernameEdit = event => {
  this.setState({
    usernameEdit : event.target.value
  })
};
handlePasswordEdit = event => {
  this.setState({
    passwordEdit : event.target.value
  })
};
handleMobileEdit = event => {
  this.setState({
    mobileEdit : event.target.value
  })
};


 
  userEditId = (id) => {
    
    let userEditId = id;
    this.props.fetchedituser({ userEditId }); 
    this.setState({
      edit: true,
      userEditId: id
    })
  }

  edituser = () => {
    if (this.state.nameEdit !== null && this.state.nameEdit !== ''  && this.state.passwordEdit !== null  && this.state.passwordEdit !== '' && this.state.mobileEdit !== null && this.state.mobileEdit !== ''  && this.state.usernameEdit !== null && this.state.usernameEdit !== '') 
     {
    const { userEditId, nameEdit, usernameEdit, passwordEdit, mobileEdit, adminEdit, catIdEdit} = this.state
    this.props.edituser({ userEditId, nameEdit, usernameEdit, passwordEdit, mobileEdit, adminEdit, catIdEdit });
     } else if (this.state.nameEdit === null | this.state.nameEdit === ''  |this.state.passwordEdit === null | this.state.passwordEdit === '' | this.state.mobileEdit === null | this.state.mobileEdit === ''  | this.state.usernameEdit === null | this.state.usernameEdit === ''){
      alert(
          'الرجاء إدخال جميع البيانات')
  }
  }

  userDeleteId = (id) => {
    this.setState({
      userDeleteId : id,
      openDelete: true,
    })
  }

  createuser = () => {

    if (this.state.name !== null && this.state.password !== null && this.state.mobile !== null && this.state.username !== null ) {
        const { name, username, password, mobile, admin, catId, permId} = this.state
        this.props.createuser({ name, username, password, mobile, admin, catId, permId });

    }  else if (this.state.name === null | this.state.password === null | this.state.mobile === null | this.state.username === null | this.state.cat === null){
      alert(
          'الرجاء إدخال جميع البيانات')
  }
}

handleUserData = (id, name, username, password, mobile, admin , default_cat) => {

  var i;
    for (i = 0; i < this.state.cats.length; i++) {
       if(this.state.cats[i].cat_id == default_cat )
       {
         this.setState({
          default_cat : this.state.cats[i].cat_name
         }) 
       } else if (default_cat == 0){
        this.setState({
          default_cat : 0
         }) 
       }
    }

  this.setState({
    moreId : id,
    moreName : name,
    moreUsername: username,
    morePassword: password,
    moreAdmin: admin,
    moreMobile: mobile,
    openMore: true
  })
}

deleteuser = () => {
  const { userDeleteId } = this.state

      this.props.deleteuser({ userDeleteId });


}

componentDidMount(){
  this.props.fetchpermissions();
  this.props.fetchcategories();
  this.props.resetusers(); 
    this.props.resetdeleteuser()
    this.props.resetedituser(); 
    this.props.fetchusers();

}
componentWillReceiveProps(props, nextProps) {
  // You don't have to do this check first, but it can help prevent an unneeded render
  
  if( props.loading !== false) 
  {
    this.props.fetchusers(); 

  }

  if( props.data ) 
  {
    this.setState({
      data: props.data
    })
    
  }

  if( props.deleteLoading !== false) 
  {
    this.props.fetchusers(); 
    this.props.resetusers(); 
    this.setState({
      openDelete: false
    })

}
if(props.editData !== undefined)
{
  this.setState({
    nameEdit: props.editData.name,
    usernameEdit: props.editData.user_name,
    passwordEdit: props.editData.user_password,
    mobileEdit: props.editData.user_mobile,
    adminEdit: props.editData.admin,
    catIdEdit: props.editData.default_cat,
  })
}
if(props.editLoading !== false)
{
  this.props.fetchusers();
  this.setState({
    edit: false,
  })
}
  
if(props.cats)
{
  this.setState({
    cats: props.cats,
    catId: 0,
    catPerm2: props.cats[0].cat_id

  })
}  

if(props.perms)
{
  this.setState({
    perms: props.perms,
    permId: 0

  })
}

}

handleSelect = event => {
  if(this.state.permId != 2)
  {
  this.setState({
    catId : 0
  })
} else 
{
this.setState({
  catId : event.target.value
})
}
}; 

handlePermissions = (event ) => {
  if(this.state.permId != 2)
  {
    this.setState({
      catId: 0,
      permId: event.target.value
    })
  } 
  else {
    this.setState({
      catId: 0,
      permId: event.target.value,
    })
  }
};

handleSelectEdit = event => {
  if(this.state.adminEdit != 2)
  {
  this.setState({
    catIdEdit : 0
  })
} else 
{
this.setState({
  catIdEdit : event.target.value
})
}
}; 

handlePermissionsEdit = (event ) => {
  if(this.state.adminEdit != 2)
  {
    this.setState({
      catIdEdit: 0,
      adminEdit: event.target.value
    })
  } 
  else {
    this.setState({
      catIdEdit: 0,
      adminEdit: event.target.value,
    })
  }
};

  render() {
    const { classes } = this.props;



    function handleClickOpen() {
        this.setState({
          open : true
        })
      }
    
      function handleClose() {
        this.setState({
          open : false,
          permId: 0
        })
      }
      
      function handleCloseEdit() {
        this.setState({
          edit : false,
        })
      }

      function handleCloseMore() {
        this.setState({
          openMore: false
        })
      }
    
      function handleDeleteClose() {
        this.setState({
          openDelete : false
        })
      }
      
      const handleChange = () => event => {
        this.setState({ 
           admin : Number(event.target.checked)
         })
      };

      const handleChangeEdit = () => event => {
        this.setState({ 
           adminEdit : Number(event.target.checked)
         })
      };
      
      console.log(this.state.adminEdit)
      console.log(this.state.catIdEdit)
    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        {this.props.data !== undefined && this.props.loading !== true && this.props.deleteLoading !== true &&this.props.editLoading!== true?
      <Grow in={true}
        {... { timeout: 2000 }}
        >
        
      <Paper >
      <Grid container  
      alignItems="center"
          justify="center" 
          spacing={1} 
          alignItems="flex-end"
          style={{padding: 10}}
          >  
          <Grid item>
          <Button className={classes.addIcon} onClick={handleClickOpen.bind(this)} >
        <AddBox fontSize='medium' style={{marginLeft:5}} />
        إضافة مستخدم جديد
      </Button>
          </Grid>
        </Grid>
        
       <div className={classes.root}>
        <Table aria-label="custom pagination table"  >
        <TableHead className="table-header" >
        <TableRow style={{height:40}} >
        <TableCell style={{fontFamily:'Cairo' , fontSize: 16}}>الإسم</TableCell>
                <TableCell style={{fontFamily:'Cairo' , fontSize: 16}}>إسم المستخدم </TableCell>
                <TableCell align="center" style={{fontFamily:'Cairo',fontSize: 16}}>الصلاحيات</TableCell>
                <TableCell align="center" style={{fontFamily:'Cairo',fontSize: 16}}>الإجراء</TableCell>
              </TableRow>
        </TableHead>
        
        <TableBody>
          {this.props.data.map(row => (
            <TableRow key={row.user_id}>
            <TableCell  style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.name}
              </TableCell>
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.user_name}
              </TableCell>
              {/*<TableCell  style={{fontFamily:'Cairo',fontSize: 16}} align="center">{row.user_password}</TableCell>
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}} align="center">{row.user_mobile}</TableCell> */}
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}} align="center">{row.per_name}</TableCell>
                <TableCell align="center" style={{padding : 0}}>
                <Button className={classes.editIcon} onClick={()=> this.userEditId(row.user_id)}>
        <Edit fontSize='small'  />
      </Button>
      <Button className={classes.deleteIcon} onClick={()=> this.userDeleteId(row.user_id)} >
        <Delete fontSize='small'  />
      </Button>
      <Button className={classes.deleteIcon} onClick={()=> this.handleUserData(row.id, row.name, row.user_name, row.user_password, row.user_mobile, row.per_name, row.default_cat)} >
        <MoreHoriz fontSize='small'  />
      </Button>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table> 

        </div>
       
    </Paper>
    </Grow> 
    : 
    <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{height: '100vh'}}
        >
    <CircularProgress style={{color: COLOR.primary}} size={50} /> 
    </Grid>
    }
    <Dialog open={this.state.open} onClose={handleClose.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>إضـافة مستخدم جديد</span></DialogTitle>
        <DialogContent>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >
         <TextField
               onChange={this.handleName}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="name"
        label="الإسم الثلاثي"
        margin="normal"
      />
        <TextField
              onChange={this.handleUsername}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="username"
        label="إسم المستخدم"
        margin="normal"
      />
       <TextField
             onChange={this.handlePassword}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="password"
        id="password"
        label="كلمة المرور"
        margin="normal"
      />
        <TextField
              onChange={this.handleMobile}
      style={{marginBottom: 10 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="number"
        id="mobile"
        label="رقم الموبايل"
        margin="normal"
      />
      <FormControl style={{marginBottom: 10, width:200}}>
  <InputLabel shrink style={{fontFamily: 'Cairo'}}>
     صلاحيات الإدارة
  </InputLabel>
  <NativeSelect
    onChange={this.handlePermissions}
    inputProps={{
      style: { fontFamily: 'Cairo'}
    }}
  >
          {this.state.perms.map(row => (
    <option value={row.per_id}>{row.per_name}</option>

    ))}
  </NativeSelect>
</FormControl>
{ this.state.permId == 2 ?
      <FormControl style={{marginBottom: 0, width:200}}>
  <InputLabel shrink style={{fontFamily: 'Cairo'}}>
    الفئـة
  </InputLabel>
  <NativeSelect
    onChange={this.handleSelect}
    inputProps={{
      style: { fontFamily: 'Cairo'}
    }}
  >
          {this.state.cats.map(row => (
    <option value={row.cat_id}>{row.cat_name}</option>

    ))}
  </NativeSelect>
</FormControl> 
: null }
      <DialogActions>

          <Button onClick={handleClose.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.loading !== true ?  <Button  color="primary" className={classes.button} onClick={this.createuser.bind(this)} >
            إضـافة  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.createuser.bind(this)} >
            إضـافة  
          </Button>  }
         { this.props.loading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 
        </Grid>
        </DialogContent>
        
       
        </div>
      </Dialog>

       {/* ---------------------EDIT---------------------- */}
       <Dialog open={this.state.edit} onClose={handleCloseEdit.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>تعديل بيانات مستخدم</span></DialogTitle>
        <DialogContent>
                       <div>
                         {this.props.editData !== undefined ?
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >

         <TextField
        defaultValue={this.state.nameEdit}
      onChange={this.handleNameEdit}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="name"
        label="الإسم الثلاثي"
        margin="normal"
      />
        <TextField
      defaultValue={this.state.usernameEdit}
      onChange={this.handleUsernameEdit}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="username"
        label="إسم المستخدم"
        margin="normal"
      />
       <TextField
      defaultValue={this.state.passwordEdit}
      onChange={this.handlePasswordEdit}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="password"
        id="password"
        label="كلمة المرور"
        margin="normal"
      />
        <TextField
       defaultValue={this.state.mobileEdit}
      onChange={this.handleMobileEdit}
      style={{marginBottom: 10 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="number"
        id="mobile"
        label="رقم الموبايل"
        margin="normal"
      />
            <FormControl style={{marginBottom: 10, width:200}}>
  <InputLabel shrink style={{fontFamily: 'Cairo'}}>
     صلاحيات الإدارة
  </InputLabel>
  <NativeSelect
    onChange={this.handlePermissionsEdit}
    inputProps={{
      style: { fontFamily: 'Cairo'},
      defaultValue: this.state.adminEdit
    }}
  >
          {this.state.perms.map(row => (
    <option value={row.per_id}>{row.per_name}</option>

    ))}
  </NativeSelect>
</FormControl>
{ this.state.adminEdit == 2 ?
      <FormControl style={{marginBottom: 0, width:200}}>
  <InputLabel shrink style={{fontFamily: 'Cairo'}}>
    الفئـة
  </InputLabel>
  <NativeSelect
    onChange={this.handleSelectEdit}
    inputProps={{
      style: { fontFamily: 'Cairo'},
      defaultValue: this.state.catIdEdit
    }}
  >
          {this.state.cats.map(row => (
    <option value={row.cat_id}>{row.cat_name}</option>

    ))}
  </NativeSelect>
</FormControl> 
: null }
      <DialogActions>

          <Button onClick={handleCloseEdit.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.editLoading !== true ?  <Button  color="primary" className={classes.button} onClick={this.edituser.bind(this)} >
            تعديل  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.edituser.bind(this)} >
            نعديل  
          </Button>  }
         { this.props.editLoading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 

        </Grid>
        :
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '100%'}}
      >
        <CircularProgress style={{color: COLOR.primary}} size={30} />  
        </Grid>
      }
        </div>
 
        </DialogContent>
        
       
        </div>
      </Dialog>

             {/* ---------------------MORE---------------------- */}
             <Dialog open={this.state.openMore} onClose={handleCloseMore.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>بيانات المستخدم</span></DialogTitle>
        <DialogContent>
                       <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >

         <TextField
         disabled
        defaultValue={this.state.moreName}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="name"
        label="الإسم الثلاثي"
        margin="normal"
      />
        <TextField
        disabled
      defaultValue={this.state.moreUsername}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="username"
        label="إسم المستخدم"
        margin="normal"
      />
       <TextField
       disabled
      defaultValue={this.state.morePassword}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
        id="password"
        label="كلمة المرور"
        margin="normal"
      />
        <TextField
        disabled
       defaultValue={this.state.moreMobile}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="number"
        id="mobile"
        label="رقم الموبايل"
        margin="normal"
      />
        <TextField
        disabled
       defaultValue={this.state.moreAdmin}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
        id="mobile"
        label="الصلاحيـات"
        margin="normal"
      />
      {this.state.default_cat !== 0 ?
      <TextField
        disabled
       defaultValue={this.state.default_cat}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
        id="cat"
        label="الفئة الأساسية"
        margin="normal"
      />  : null }
      <DialogActions>

          <Button onClick={handleCloseMore.bind(this)}  className={classes.button} elevation="1">
            اغلاق
          </Button>
        </DialogActions> 

        </Grid>
        </div>
 
        </DialogContent>
        
       
        </div>
      </Dialog>
{/*--------------------------------------------------*/}

       <Dialog open={this.state.openDelete} onClose={handleDeleteClose.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>تأكيد حذف المستخدم</span></DialogTitle>
        <DialogContent>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >
      <DialogActions>
          <Button onClick={handleDeleteClose.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.deleteLoading !== true ?  <Button  color="primary" className={classes.button} onClick={this.deleteuser.bind(this)} >
            تأكيد  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.deleteuser.bind(this)} >
          تأكيد  
          </Button>  }
         { this.props.deleteLoading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 
        </Grid>
        </DialogContent>
        
       
        </div>
      </Dialog>
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}
const mapStateToProps = state => {
  return {
      loading : state.createuser.loading,
      success: state.createuser.success,
      message: state.createuser.message,
      data: state.fetchusers.data,
      deleteLoading: state.deleteuser.deleteLoading,
      deleteMessage: state.deleteuser.deleteMessage,
      deleteSuccess: state.deleteuser.deleteSuccess,
      reset: state.deleteuser.reset,
      editData: state.fetchedituser.data,
      editSuccess: state.edituser.success,
      editLoading: state.edituser.loading,
      editMessage: state.edituser.message,
      cats: state.fetchcategories.data,
      perms: state.fetchpermissions.data

  }
}


export default withStyles(styles)(connect(mapStateToProps, { createuser , fetchusers, fetchcategories , fetchpermissions , resetusers, deleteuser , resetdeleteuser, fetchedituser, edituser, resetedituser})(Users));
