import logo from './logo.svg';
import React, { Component  } from 'react'
import './App.css';
import { MuiThemeProvider, createMuiTheme ,withStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/icons/List';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import {fetchaddresses, fetchcategoriesmap, fetchaddressesmap, searchmap  } from './redux/actions';
import { connect } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { amber } from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import LocationOn from '@material-ui/icons/LocationOn';

const theme = createMuiTheme({
  direction: "rtl",
 
});

const styles = theme => ({
  divStyle: {
    background: '#000',
margin: '5px',
fontFamily: 'Cairo',
fontSize : 20,
color:'#000',
'&:hover': {
background: '#000',
},
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 450,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
    backgroundColor: '#E9E4C8'
  },
  logo: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
});





const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


class Mapp extends Component {

  constructor(props) {
    super(props);
    this.state =  {
        lat:  32.439064,
        lng: 14.550571,
        open: false,
        openList: false,
        input: '',
        anchorEl: null,
        catId: null,
        noAddress: false,
        search: null,
        name: null,
        addressNumber: null,
        mobileNumber: null,
        viewAddresses: true,
        viewAddressesCat: false,
        zoom: 12,
        ...props
    };
  }

  addresses= (id) => {
    let catId = id;
    this.props.fetchaddressesmap({ catId }); 
    this.setState({
      openList: false,
      viewAddresses: false,
      viewAddressesCat: true,
      zoom:12
    })
  }

  fetchaddresses= () => {
    this.props.fetchaddresses();
    this.setState({
      openList: false,
      viewAddresses: true,
      viewAddressesCat: false,
      zoom: 12
    })
  }

  componentDidMount(){
        this.props.fetchaddresses();
    this.props.fetchcategoriesmap();
    const { input  } = this.state
    this.props.searchmap({ input });
  }

  componentWillReceiveProps(props, nextProps) {

    if(props.cats)
    {
      this.setState({
        cats: props.cats,
      })
    }  

    if(props.addresses && props.addresses.length != 0)
    {
      this.setState({
        addresses: props.addresses,
        noAddress: false
      })
    }

    if(props.address !== undefined && props.address !== null)
    {
      this.setState({
        address: props.address,
      })
    }

    if(props.addresses === null )
    {
      this.setState({
        noAddress: true,
      })
    }
    }

  handleInput = event => {
    this.setState({
      input : event.target.value
    })
    const { input  } = this.state
    this.props.searchmap({ input });
    if(isNaN(this.state.input))
    {
      this.setState({
        marker: false
      })
    }
};

 handleAddress = (name,lat,lng,address,mobile) => {
   this.setState({
     searchh: name,
     address: [],
     addresses: [],
     noAddress: false,
     input: name,
     name: name,
     lat : lat,
     lng : lng,
     marker: true,
     addressNumber: address,
     mobileNumber: mobile,
     noAddress: false,
     zoom: 17
   })
};



onMarkerClick = (name,lat,lng,address,mobile) => {
  this.setState({
    marker: true,
      name: name,
      addressNumber: address,
      mobileNumber: mobile,
      lat: lat,
      lng: lng,
      noAddress: false,
      zoom: 17
  })
  }

  
  render() {
   
    const { classes } = this.props;


    const handleClick = event => {
      this.setState({
        anchorEl: event.currentTarget,
        openList: true,
        searchh: null,
        marker: false,
        noAddress: false
      })
     
    };
  
    const handleClose = () => {
      this.setState({
        anchorEl: null,
        openList: false
      })
    };

    const handleSnackClose = () => {
      this.setState({
        noAddress: false,
      });
    };

    
    const handleListClick = event => {
      this.setState({
        openList: true
      })
    };

    const googleMaps = () => {
      window.open("https://maps.google.com?q="+this.state.lat+","+this.state.lng );
    };
  
    const handleListClose = () => {
      this.setState({
        openList: false
      })
    };
    console.log(this.props.addressLoading)
    return (
        <StylesProvider jss={jss}>
        <MuiThemeProvider theme={theme}> 
        {this.state.name != null ?
               
        <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={this.state.marker}
        TransitionComponent={Slide}
        style={{textAlign: 'center'}}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent style={{background: 'linear-gradient(160deg,#eadca6,#e5d397,#e0ca89,#dcc17a,#d7b76c,#d3ae5e,#cfa550,#cb9b42)', fontFamily: 'Cairo'}} 
        message={
          <Grid
  container
  direction="column"
  alignItems= 'right'
  style={{padding: 0, width:300}}
>
 <span style={{fontSize: 20,color: '#211717', paddingBottom: 5}}>{this.state.name}</span>
 <span style={{fontSize: 35,color: '#211717', paddingBottom: 5}}>{this.state.addressNumber}</span>
        <Button  style={{backgroundColor: '#fff', fontFamily: 'Cairo', marginBottom: 5, width: '100%', color:'#000'}}variant="contained" onClick={googleMaps} >
        <LocationOn /> الوصول الي الموقع 
  </Button>

  </Grid>}>
        </SnackbarContent>
      </Snackbar> : null }
        <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={this.state.noAddress}
        onClose={handleSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
       <SnackbarContent style={{backgroundColor: theme.palette.error.dark ,fontFamily: 'Cairo'}} 
        message={<span style={{textAlign: 'center', fontFamily: 'Cairo'}}>لا توجد متاجر مسجلة في هذه الفئة</span>}>
        </SnackbarContent>
      </Snackbar>
      {this.props.addressesLoading !== false || this.props.addressLoading !== false ?
       <div className = "loading">
      <Grid container  
            justify="center" 
            alignItems="center"
            style={{height: '80vh', width: '80vw'}}
            >
            <Fab style={{backgroundColor: '#CEB986'}}  disabled>
            <CircularProgress color="#fff"/>
        </Fab>
        
            </Grid>
      </div> : null }
       
        <Grid container  
        alignItems="center"
            justify="center" 
            spacing={1} 
            alignItems="flex-end"
            style={{padding: 10}}
            >
        <div className = "overMapp">
        
     <Paper component="form" className={classes.root}>
        <div className={classes.logo} aria-label="menu">
        <img src={require('./images/zliten.png')} style={{width:40 , height:40}} />
        </div>
        <Autocomplete
        style={{ width: 450 }}
        open={this.state.open}
        onOpen={() => {
          this.setState({open: true})
        }}
        onClose={() => {
          this.setState({open: false})
        }}
        getOptionLabel={option => option.name}
        renderOption={option => (
          <React.Fragment>
            <Grid container  
            alignItems="flex-start"
            justify="flex-start" 
            spacing={1} 
            style={{padding: 10}}
            >
            {option.name}
            </Grid>
            <Grid container  
            alignItems="flex-end"
            justify="flex-end" 
            spacing={4} 
            style={{padding: 10}}
            >
             <span style={{backgroundColor:'#CFAD71', color: '#000',textAlign: 'left', padding: 5, borderRadius: 5}}>
             {option.address} 
             </span>
             </Grid>
          </React.Fragment>
        )}
        options={this.props.data}
        loading={this.props.loading}  
        loadingText={'جاري البحث ...'}
        noOptionsText={'لا توجد نتائج'}
        onChange={(e,v) => v !== null ? this.handleAddress(v.name,v.lat,v.lng,v.address,v.mobile) : this.handleAddress(this.state.name,this.state.lat,this.state.lng,this.state.address,this.state.mobile)}
        renderInput={params => (
          <TextField
            {...params}
            label="البحث عن المتاجر"
            fullWidth
            onChange={event => console.log(event.target.value)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  ,
               endAdornment: (
               <React.Fragment>
                  {this.props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
              </React.Fragment>
              ),  
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
              style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' 
            }}}
  
          />
        )}
      />
<Button onClick={handleClick}>
          <List /> 
        </Button>
        <Popover
          open={this.state.openList}
          anchorEl={this.state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {backgroundColor: 'transparent'},
            elevation: '0'
          }}
        >
        <Grid
  container
  direction="column"
  justify="center"
  alignItems="center"
  style={{marginTop:20}}
> <Button style={{backgroundColor: '#CEB986', fontFamily: 'Cairo', marginBottom: 5, width:300}}variant="contained" onClick={()=> this.fetchaddresses()} >
  عرض جميع الفئات
  </Button>
         {this.state.cats.map(cat => (
               <Button style={{backgroundColor: '#CEB986', fontFamily: 'Cairo', marginBottom: 5, width:300}}variant="contained" onClick={()=> this.addresses(cat.cat_id)} >
  {cat.cat_name}
  </Button>
  ))}
  </Grid>
        </Popover>

      
      </Paper>
      </div>
      </Grid>
      <div style={{marginTop: -20,}}>
      <Map google={this.props.google} zoom={this.state.zoom} style={{width:'100%'}}
      onClick={() => 
        this.setState({marker: false, zoom:12})}
       initialCenter={{lat:  this.state.lat , lng: this.state.lng}} 
       center={{lat:  this.state.lat , lng: this.state.lng}} 
      fullscreenControl={false}
      rotateControl={false}
      panControl={false}
      streetViewControl={false}
      scaleControl={false}
      zoomControl={false}
      mapTypeControl={true}
      >
      {/*
    {this.state.address.length > 0 ? this.state.address.map( address => (
    <Marker
    onClick={() => this.onMarkerClick(address.name,address.lat,address.lng, address.address, address.mobile)}
    position={{lat:  address.lat , lng: address.lng}}  />
    ))
    : 
    this.state.addresses.length > 0  
    ? 
    this.state.addresses.map( address => (
      <Marker
      onClick={() => this.onMarkerClick(address.name,address.lat,address.lng, address.address, address.mobile)}
      position={{lat:  address.lat , lng: address.lng}}  />
      ))
    :
    <Marker
    onClick={() => this.onMarkerClick(this.state.name,this.state.lat,this.state.lng, this.state.addressNumber, this.state.mobileNumber)}
    position={{lat:  this.state.lat , lng: this.state.lng}}  />
    }
  */ }  
  {this.state.viewAddresses !== false ? this.state.address.map( address => (
    <Marker
    onClick={() => this.onMarkerClick(address.name,address.lat,address.lng, address.address, address.mobile)}
    position={{lat:  address.lat , lng: address.lng}}  />
    ))
    : null }

{this.state.viewAddressesCat !== false  ? this.state.addresses.map( address => (
    <Marker
    onClick={() => this.onMarkerClick(address.name,address.lat,address.lng, address.address, address.mobile)}
    position={{lat:  address.lat , lng: address.lng}}  />
    ))
    : null }

{ this.state.searchh !== null ? 
  <Marker
  onClick={() => this.onMarkerClick(this.state.name,this.state.lat,this.state.lng, this.state.addressNumber, this.state.mobileNumber)}
  position={{lat:  this.state.lat , lng: this.state.lng}}  />
  
    : null }
    </Map>
    </div>
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}
  
const mapStateToProps = state => {
  return {
      cats: state.fetchcatsmap.data,
      catsLoading: state.fetchcatsmap.loading,
      data: state.datamap.data,
      loading: state.datamap.loading,
      addresses: state.fetchaddressesmap.data,
      addressesLoading: state.fetchaddressesmap.loading,
      address: state.fetchaddresses.data,
      addressLoading: state.fetchaddresses.loading

  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyDFdoN0NF6ue3dLmPRE8Id8XMVCZ1Xr434")
})(connect(mapStateToProps, { fetchaddresses, searchmap, fetchcategoriesmap ,fetchaddressesmap })((withStyles(styles))(Mapp)));