
import { DELETE_ADDRESS_ATTEMPT, DELETE_ADDRESS_SUCCESS, DELETE_ADDRESS_FAILED } from '../types'
import axios from 'axios';
export const deleteaddress = ({ addressIdDelete })=> {
    return (dispatch) => {
        dispatch({ type: DELETE_ADDRESS_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/deleteAddress.php', { addressIdDelete })
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message )
        console.log('failed')
        console.log(data.message)
    } else {
        onSuccess(dispatch, data.message)
        console.log('success')
        console.log(data.message)
    }
}
const onSuccess = (dispatch, message) => {
    dispatch({ type: DELETE_ADDRESS_SUCCESS, message: message })

}
const onFailed = (dispatch, message) => {
    dispatch({ type: DELETE_ADDRESS_FAILED, message: message })
}