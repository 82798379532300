import React , {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import COLOR from './color';
import { auth } from './redux/actions';
import { connect } from "react-redux";
import Grow from '@material-ui/core/Grow';
import cookie from 'react-cookies';

import './App.css';

const theme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: grey,
  },
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


class App extends Component {

  constructor(props) {
    super(props);
    this.state =  {
    admin: false,
    username: null,
    password: null,
    loading: true,
    loadingLogo: true ,
    users: [],
    };
  }

  handleUsername = event => {
    this.setState({
      username : event.target.value
    })
  };
  
  handlePassword = event => {
    this.setState({
      password : event.target.value
    })
  };

  auth = () => {
    setInterval(() => {
    }, 1000);
    if (this.state.username != null && this.state.password !== null) {
        const { username, password } = this.state
        this.props.auth({ username, password });

    }  else if (this.state.username === null && this.state.password === null ){
      alert(
          'الرجاء إدخال اسم المستخدم وكلمة المرور')
  } 
}


componentDidMount() {
  setInterval(() => {
    this.setState({ loadingLogo: !this.state.loadingLogo });
  }, 500);
}


  render() {
   
    if(this.props.name !== null && this.props.admin !== null)
    {
      cookie.save('name', this.props.name, { path: '/' })
      cookie.save('username', this.props.username, { path: '/' })
      cookie.save('admin', this.props.admin, { path: '/' })
      cookie.save('id', this.props.id, { path: '/' })
      cookie.save('cat', this.props.default_cat, { path: '/' })

    }
    
    if (cookie.load('admin') == 0) {
      return <Redirect to='/user' />
    } else if  (cookie.load('admin') == 1) {
      return <Redirect to='/admin' />
    } else if  (cookie.load('admin') == 2) {
      return <Redirect to={cookie.load('username')} />
    }

    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
      <div>
      { this.props.loading === true ?
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{height: '100vh',}}
      >
      <Grow in={this.state.loadingLogo}
        {... { timeout: 500 }}
        >
      <img src={require('./images/zliten.png')} style={{width:150 , height:150, marginBottom:50}} />
      </Grow>
      </Grid>
        :
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%', marginTop:120}}
        >
        
      <img src={require('./images/zliten.png')} style={{width:150 , height:150, marginBottom:50}} />


      <TextField
      onChange={this.handleUsername}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="standard-name"
        label="إسم المستخدم"
        margin="normal"
      />
      <TextField
      onChange={this.handlePassword}
      style={{marginBottom: 15, width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
      id="standard-password-input"
      label="كلمة المرور"
      type="password"
        margin="normal"
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            // console.log('Enter key pressed');
            this.auth();
          }
        }}
      />
      <Button variant="contained"  onClick={this.auth.bind(this)}  style={{fontFamily: 'Cairo' , fontSize : 15 , width:200 , background: COLOR.primaryG, color: '#000'}}>
        تسجيـل الدخول
      </Button>
        <p style={{fontFamily: 'Cairo' , color:'#DA3334' , fontSize: 14}}>
          {this.props.error}
        </p>
    </Grid>
    }
    </div>
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}
  

const mapStateToProps = state => {
  return {
      id: state.auth.id,
      loading : state.auth.loading,
      name: state.auth.name,
      admin: state.auth.admin,
      error: state.auth.error,
      default_cat: state.auth.default_cat,
      username: state.auth.username
  }
}

export default (connect(mapStateToProps, { auth })(App));
