import { CREATE_ADDRESS_ATTEMPT, CREATE_ADDRESS_SUCCESS, CREATE_ADDRESS_FAILED } from '../../actions/types'
import axios from 'axios';
export const sendData = ({ name, mobile, lat, lng, userId, catId}) => {

    return (dispatch) => {
        dispatch({ type: CREATE_ADDRESS_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/onwan/customer_number_new/connDB/new_customer_number2.php', { name, mobile, lat, lng, userId, catId })
              .then((resp) => handleResponse(dispatch, resp.data)) 
           /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message, data.success)
        //console.log('Failed')
        //console.log(data)
    } else {
        onSuccess(dispatch, data.message, data.success ,data.address )
        //console.log('Success')
        //console.log(data)
    }
}
const onSuccess = (dispatch, message, success, address) => {
    dispatch({ type: CREATE_ADDRESS_SUCCESS, message: message , success: success, address: address })
}

const onFailed = (dispatch,  message, success) => {
    dispatch({ type: CREATE_ADDRESS_FAILED,  message: 'failed' , success: success  })
}