import { EDIT_ADDRESS_ATTEMPT_EDITOR, EDIT_ADDRESS_SUCCESS_EDITOR, EDIT_ADDRESS_FAILED_EDITOR , RESET_EDIT_ADDRESS_EDITOR} from '../../../actions/types'
const INITIAL_STATE = {
    loading: false,
    message: null ,
    success: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EDIT_ADDRESS_ATTEMPT_EDITOR:
            return { ...state, loading: true , success: false}
        case EDIT_ADDRESS_SUCCESS_EDITOR:
            return { ...state, loading: false, message: action.message, success: action.success  }
        case EDIT_ADDRESS_FAILED_EDITOR:
             return { ...state, loading: false, message: action.message, success: action.success }
        case RESET_EDIT_ADDRESS_EDITOR:
             return { ...state, loading: false , success: false, message: null }
        default:
            return state;
    }
}