import { SEARCH_ATTEMPT, SEARCH_SUCCESS, SEARCH_FAILED } from '../types'
import axios from 'axios';
export const searchAddress = ({ mobile  ,firstDate, secondDate })=> {

    return (dispatch) => {
        dispatch({ type: SEARCH_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/search.php', { mobile ,  firstDate, secondDate })
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))  */
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
    if (data === null) {
        onFailed(dispatch, data)
        console.log('Failed')
        console.log(data)
    } else {
        onSuccess(dispatch, data )
        console.log('Success')
        console.log(data)
    }
}
const onSuccess = (dispatch, data) => {
    dispatch({ type: SEARCH_SUCCESS, data: data })
}

const onFailed = (dispatch, data) => {
    dispatch({ type: SEARCH_FAILED, data: data })
}