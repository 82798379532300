import { FETCH_CATEGORY_EDITOR } from '../../types'
import axios from 'axios';
export const fetchcategory = ({ userId }) => {

    return (dispatch) => {
        dispatch({ type: FETCH_CATEGORY_EDITOR });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/editor/fetchCat.php', { userId })
            .then((resp) => dispatch({ type: FETCH_CATEGORY_EDITOR, data: resp.data }))
             /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
