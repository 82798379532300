import { combineReducers } from 'redux';
import Auth from './auth';
import Main from './main';


// Users import

import createUser from './users/createuser';
import fetchUsers from './users/fetchusers';
import deleteUser from './users/deleteuser';
import fetchEditUser from './users/fetchedituser';
import editUser from'./users/edituser';
import fetchPermissions from'./users/fetchpermissions';

// Categories import

import createCat from './categories/createcat';
import fetchCats from './categories/fetchcats';
import deleteCat from './categories/deletecat';
import fetchEditCat from './categories/fetcheditcat';
import editCat from'./categories/editcat';


// Addresses import

import fetchAddresses from './address/fetchaddresses';
import deleteAddress from './address/deleteaddress';
import fetchEditAddress from './address/fetcheditaddress';
import editAddress from'./address/editaddress';
import fetchCategories from'./address/fetchcategories';
import addAddress from'./address/addaddress';
import search from'./address/search';
import searchDate from'./address/searchdate';


// Mapp component import

import Data from './data';
import fetchCategoriesMapp from './fetchcategories';
import fetchAddressesMapp from './fetchaddresses';

// Editor import

import fetchAddressesEditor from './address/editor/fetchaddresses';
import fetchCategory from './address/editor/fetchcategory';
import editAddressEditor from './address/editor/editaddress';
import searchAddressEditor from './address/editor/search';

// Editor import {map} 


import searchMapEditor from './address/editor/data';
import fetchAddressesEditorMap from './address/editor/fetchaddresseseditor';




const rootReducer = combineReducers({
    auth: Auth,
    main: Main,
    createuser: createUser,
    fetchusers: fetchUsers,
    deleteuser: deleteUser,
    fetchedituser: fetchEditUser,
    edituser: editUser,

    // Categories

    createcat: createCat,
    fetchcats: fetchCats,
    deletecat: deleteCat,
    fetcheditcat: fetchEditCat,
    editcat: editCat,

    // Addresses

    fetchaddresses: fetchAddresses,
    deleteaddress: deleteAddress,
    fetcheditaddress: fetchEditAddress,
    editaddress: editAddress,
    fetchcategories: fetchCategories,
    addaddress: addAddress,
    searchAddress: search,
    searchdate: searchDate,
    fetchpermissions: fetchPermissions,

    // Mapp 

    datamap: Data,
    fetchcatsmap: fetchCategoriesMapp,
    fetchaddressesmap: fetchAddressesMapp,

    // Editor

    fetchaddresseseditor: fetchAddressesEditor,
    fetchcategory: fetchCategory,
    editaddresseditor: editAddressEditor,
    searchaddresseditor : searchAddressEditor,
    searchmapeditor : searchMapEditor,
    fetchaddresseseditormap :fetchAddressesEditorMap

});

export default rootReducer;

