import { FETCH_CATEGORIES } from '../types'
import axios from 'axios';
export const fetchcategories = () => {

    return (dispatch) => {
        dispatch({ type: FETCH_CATEGORIES });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/fetchCats.php')
             .then((resp) => dispatch({ type: FETCH_CATEGORIES, data: resp.data }))
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
