
import { SEARCH_ATTEMPT, SEARCH_SUCCESS, SEARCH_FAILED , RESET_SEARCH} from '../../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false,
    success: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_ATTEMPT:
            return { ...state, loading: true , success: false}
        case SEARCH_SUCCESS:
            return { ...state, data: action.data,loading: false,  success: action.success  }
        case SEARCH_FAILED:
             return { ...state,data: action.data, loading: false, success: action.success }
        case RESET_SEARCH:
             return { ...state, loading: false , success: false, data: [] }
        default:
            return state;
    }
}