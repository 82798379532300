import { FETCH_ADDRESSES_ATTEMP_MAP, FETCH_ADDRESSES_MAP} from '../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADDRESSES_ATTEMP_MAP:
            return { ...state, loading: true}
        case FETCH_ADDRESSES_MAP:
            return { ...state, data: action.data , loading: false}
       default:
            return state;
    }
}