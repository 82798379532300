
import { CREATECAT_ATTEMPT, CREATECAT_SUCCESS, CREATECAT_FAILED } from '../types'
import axios from 'axios';
export const createcat = ({ name, id, view }) => {

    return (dispatch) => {
        dispatch({ type: CREATECAT_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/categories/createCat.php', { name, id, view  })
             .then((resp) => handleResponse(dispatch, resp.data))
             /*.then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message , data.admin)
        console.log('failed')
        console.log(data.message)
    } else {
        onSuccess(dispatch, data.message  , data.admin )
        console.log('success')
        console.log(data.message)
        

        

    }
}
const onSuccess = (dispatch, message) => {
    dispatch({ type: CREATECAT_SUCCESS, message: message })

}

const onFailed = (dispatch, message) => {
    dispatch({ type: CREATECAT_FAILED, message: message })
}