import React , {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme ,withStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Button from '@material-ui/core/Button';
import COLOR from './color';
import './App.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Grow from '@material-ui/core/Grow';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createcat , fetchcats , resetcats , deletecat, resetdeletecat , fetcheditcat ,editcat, reseteditcat} from './redux/actions';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import cookie from 'react-cookies';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const theme = createMuiTheme({
    direction: "rtl",
    palette: {
        primary: {
            main: '#000'
          },

    },
  });

const styles = theme => ({
    root: {
      overflowX: "auto",
      width: '353px',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
        
    },
    pagination: {
     display: 'none',
     fontFamily:'Cairo',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        display: 'flex',
        fontFamily:'Cairo',
      },

        
    },
    pageControls : {
      width: 500,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
      input: {
        fontFamily: 'Cairo',
        fontSize: 18,
        width:150
        
      },
      iconButton: {
        background: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
      },
       editIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
      },
      deleteIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
        margin:5
      },
      addIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
        fontFamily: 'Cairo',
        fontSize:16,
        
      },
      title: {
        fontFamily: 'Cairo',
        fontSize : 25,
        textAlign: 'center'
        
      },
      button: {
        background: COLOR.secondary,
  margin: '5px',
  fontFamily: 'Cairo',
  fontSize : 16,
  color: '#000',
  '&:hover': {
    background: COLOR.secondary,
 },
      },
  });

  
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


class Cats extends Component {

  constructor(props) {
    super(props);
    this.state = {
        page: 0,
        rowsPerPage: 5,
        open: false,
        name: null,
        nameEdit: null,
        catEditId: null,
        catDeleteId: null,
        openDelete: false,
        edit: false,
        editSuccess: false,
        id: null,
        view: 0,
        viewEdit: 0,
        ...props        
    };
  }
   /* ------------------------------ADD FUNCTIONS------------------------------ */

  handleName = event => {
    this.setState({
      name : event.target.value
    })
  };

 /* ------------------------------EDIT FUNCTIONS------------------------------ */


 handleNameEdit = event => {
  this.setState({
    nameEdit : event.target.value
  })
};

 
  catEditId = (id) => {
    
    let catEditId = id;
    this.props.fetcheditcat({ catEditId }); 
    this.setState({
      edit: true,
      catEditId: id
    }) 
  }

  editcat = () => {
    if (this.state.nameEdit !== null && this.state.nameEdit !== '') 
     {
      let userId = cookie.load('id');
    const { catEditId, nameEdit, viewEdit } = this.state
    this.props.editcat({ catEditId, nameEdit, userId ,viewEdit});
     } else if (this.state.nameEdit === null | this.state.nameEdit === ''){
      alert(
          'الرجاء إدخال جميع البيانات')
  }
  }

  catDeleteId = (id) => {
    this.setState({
      catDeleteId : id,
      openDelete: true,
    })
  }

  createcat = () => {

    if (this.state.name !== null && this.state.name !== '') {
        const { name, view } = this.state
        let id = cookie.load('id');
        this.props.createcat({ name, id, view });

    }  else if (this.state.name === null | this.state.name === ''){
      alert(
          'الرجاء إدخال جميع البيانات')
  }
}

deletecat = () => {
  const { catDeleteId } = this.state

      this.props.deletecat({ catDeleteId });


}

componentDidMount(){
    this.props.resetcats(); 
    this.props.resetdeletecat()
    this.props.reseteditcat(); 
    this.props.fetchcats();

}
componentWillReceiveProps(props, nextProps) {
  // You don't have to do this check first, but it can help prevent an unneeded render

  if( props.loading !== false) 
  {
    this.props.fetchcats(); 
    this.setState({
      open: false,
    })
    
  }

  if( props.deleteLoading !== false) 
  {
    this.props.fetchcats(); 
    this.setState({
      openDelete: false,
    })

}
if(props.editData !== undefined)
{
  this.setState({
    nameEdit: props.editData.cat_name,
    viewEdit: props.editData.view,
  })
}
if(props.editLoading !== false)
{
  this.props.fetchcats();
  this.setState({
    edit: false,
  })
}
  
}

  render() {
    const { classes } = this.props;

    function handleClickOpen() {
        this.setState({
          open : true
        })
      }
    
      function handleClose() {
        this.setState({
          open : false
        })
      }
      
      function handleCloseEdit() {
        this.setState({
          edit : false
        })
      }

      function handleDeleteOpen() {
        this.setState({
          openDelete : true
        })
      }
    
      function handleDeleteClose() {
        this.setState({
          openDelete : false
        })
      }

      function handleSnackClose() {
        this.setState({
          success : false,
          message : null,
        })
      }

      function handleDeleteSnackClose() {
        this.setState({
          deleteSuccess : false,
        })
      }

      function handleEditSnackClose() {
        this.setState({
          editSuccess : false,
          editMessage: null
        })
      }

      /*console.log(this.state.name)
      console.log(this.state.username)
      console.log(this.state.password)
      console.log(this.state.mobile)
      console.log(this.state.deleteLoading)
      console.log(this.state.deleteMessage)
      console.log(this.state.deleteSuccess)
      console.log(this.props.deleteLoading)*/

      const handleChange = () => event => {
        this.setState({ 
           view : Number(event.target.checked)
         })
      };

      const handleChangeEdit = () => event => {
        this.setState({ 
           viewEdit : Number(event.target.checked)
         })
      };
      


    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        {this.props.data !== undefined && this.props.loading !== true && this.props.deleteLoading !== true &&this.props.editLoading!== true?
      <Grow in={true}
        {... { timeout: 2000 }}
        >
        
      <Paper >
      <Grid container  
      alignItems="center"
          justify="center" 
          spacing={1} 
          alignItems="flex-end"
          style={{padding: 10}}
          >  
          <Grid item>
          <Button className={classes.addIcon} onClick={handleClickOpen.bind(this)} >
        <AddBox fontSize='medium' style={{marginLeft:5}} />
        إضافة فئة جديدة
      </Button>
          </Grid>
        </Grid>
        
       <div className={classes.root}>
        <Table aria-label="custom pagination table"  >
        <TableHead className="table-header" >
        <TableRow style={{height:40}} >
        <TableCell align="center" style={{fontFamily:'Cairo' , fontSize: 16}}>إسم الفئة</TableCell>
        <TableCell  style={{fontFamily:'Cairo' , fontSize: 16}}>العرض علي الدليل</TableCell>
                <TableCell align="center" style={{fontFamily:'Cairo' , fontSize: 16}}>عدد العناوين</TableCell>
                <TableCell align="center"  style={{fontFamily:'Cairo',fontSize: 16}}>
                الإجراء
              </TableCell>
              </TableRow>
        </TableHead>
        
        <TableBody>
          {this.props.data.map(row => (
            <TableRow key={row.cat_id} >
            <TableCell align="center" style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.cat_name}
              </TableCell>
              <TableCell align="center" style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.view !== '0' ?
                <div style={{backgroundColor: '#2cac72', width: 100, color: '#000' , borderRadius: 5 }}>
                  نعم
                </div>
                :
                <div style={{backgroundColor: '#bf5260',  width: 100, color: '#000', borderRadius: 5}}>
                   لا
                </div>
                }
              </TableCell>
              <TableCell align="center" style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.count}
              </TableCell>

                <TableCell  align="center" style={{padding : 0}}>
                <Button className={classes.editIcon} onClick={()=> this.catEditId(row.cat_id)}>
        <Edit fontSize='small'  />
      </Button>
      <Button className={classes.deleteIcon} onClick={()=> this.catDeleteId(row.cat_id)} >
        <Delete fontSize='small'  />
      </Button>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table> 

        </div>
       
    </Paper>
    </Grow> 
    : 
    <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{height: '100vh'}}
        >
    <CircularProgress style={{color: COLOR.primary}} size={50} /> 
    </Grid>
    }
    <Dialog open={this.state.open} onClose={handleClose.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>إضـافة فئة جديد</span></DialogTitle>
        <DialogContent>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >
         <TextField
               onChange={this.handleName}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="name"
        label="إسم الفئـة"
        margin="normal"
      />
      <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.view}
            onChange={handleChange()}
            value="checkedB"
            style={{color:'#000' , fontFamily:'Cairo'}}
          />
        }
        inputProps={{
            style: {color:'#000' , fontFamily:'Cairo'}
        }}
        label={<span style={{ fontFamily: 'Cairo'}}>العرض علي الدليل</span>}
      />
      </FormGroup>
      <DialogActions>

          <Button onClick={handleClose.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.loading !== true ?  <Button  color="primary" className={classes.button} onClick={this.createcat.bind(this)} >
            إضـافة  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.createcat.bind(this)} >
            إضـافة  
          </Button>  }
         { this.props.loading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 
        </Grid>
        </DialogContent>
        
       
        </div>
      </Dialog>

       {/* ---------------------EDIT---------------------- */}
       <Dialog open={this.state.edit} onClose={handleCloseEdit.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>تعديل بيانات الفئة</span></DialogTitle>
        <DialogContent>
                       <div>
                         {this.props.editData !== undefined ?
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >
 <TextField
 defaultValue={this.state.nameEdit}
               onChange={this.handleNameEdit}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="name"
        label="إسم الفئـة"
        margin="normal"
      />
      <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
         checked={Number(this.state.viewEdit)}
            onChange={handleChangeEdit()}
            value="checkedB"
            style={{color:'#000' , fontFamily:'Cairo'}}
          />
        }
        inputProps={{
            style: {color:'#000' , fontFamily:'Cairo'}
        }}
        label={<span style={{ fontFamily: 'Cairo'}}>العرض علي الدليل</span>}
      />
      </FormGroup>
      <DialogActions>

          <Button onClick={handleCloseEdit.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.editLoading !== true ?  <Button  color="primary" className={classes.button} onClick={this.editcat.bind(this)} >
            تعديل  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.editcat.bind(this)} >
            نعديل  
          </Button>  }
         { this.props.editLoading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 

        </Grid>
        :
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '100%'}}
      >
        <CircularProgress style={{color: COLOR.primary}} size={30} />  
        </Grid>
      }
        </div>
 
        </DialogContent>
        
       
        </div>
      </Dialog>

      
{/*--------------------------------------------------*/}

       <Dialog open={this.state.openDelete} onClose={handleDeleteClose.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>تأكيد حذف الفئـة</span></DialogTitle>
        <DialogContent>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >
      <DialogActions>
          <Button onClick={handleDeleteClose.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.deleteLoading !== true ?  <Button  color="primary" className={classes.button} onClick={this.deletecat.bind(this)} >
            تأكيد  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.deletecat.bind(this)} >
          تأكيد  
          </Button>  }
         { this.props.deleteLoading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 
        </Grid>
        </DialogContent>
        
       
        </div>
      </Dialog>
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}
const mapStateToProps = state => {
  return {
      loading : state.createcat.loading,
      success: state.createcat.success,
      message: state.createcat.message,
      data: state.fetchcats.data,
      deleteLoading: state.deletecat.deleteLoading,
      deleteMessage: state.deletecat.deleteMessage,
      deleteSuccess: state.deletecat.deleteSuccess,
      reset: state.deletecat.reset,
      editData: state.fetcheditcat.data,
      editSuccess: state.editcat.success,
      editLoading: state.editcat.loading,
      editMessage: state.editcat.message,
  }
}


export default withStyles(styles)(connect(mapStateToProps, { createcat , fetchcats, resetcats, deletecat , resetdeletecat, fetcheditcat, editcat, reseteditcat})(Cats));
