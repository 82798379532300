import { FETCH_ADDRESSES , FETCH_ADDRESSES_ATTEMPT} from '../../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADDRESSES_ATTEMPT:
            return { ...state, loading: true }
        case FETCH_ADDRESSES:
            return { ...state, data: action.data, loading: false }
       default:
            return state;
    }
}