import { DATE_ATTEMPT, DATE_SUCCESS, DATE_FAILED } from '../types'
import axios from 'axios';
export const searchdate = ({ firstDate, secondDate })=> {

    return (dispatch) => {
        dispatch({ type: DATE_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/searchDate.php', { firstDate, secondDate })
            .then((resp) => handleResponse(dispatch, resp.data))
             /* .then((resp) => console.log(resp.data))  */
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
        onSuccess(dispatch, data )
        console.log('Success')
        console.log(data)
    
}
const onSuccess = (dispatch, data) => {
    dispatch({ type: DATE_SUCCESS, data: data  })
}
