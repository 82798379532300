import React , {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme ,withStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import COLOR from './color';
import './App.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import Grow from '@material-ui/core/Grow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Delete from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Edit from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {  fetchaddresses , resetaddresses , deleteaddress, resetdeleteaddress , fetcheditaddress ,editaddress, reseteditaddress, fetchcategories, searchAddress, resetsearch, searchdate} from './redux/actions';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import cookie from 'react-cookies';

const theme = createMuiTheme({
    direction: "rtl",
    palette: {
      primary: grey,
    },
  });

const styles = theme => ({
    root: {
      overflowX: "auto",
      width: '353px',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
        
    },
    pagination: {
     display: 'none',
     fontFamily:'Cairo',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        display: 'flex',
        fontFamily:'Cairo',
      },

        
    },
    pageControls : {
      width: 500,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
      input: {
        fontFamily: 'Cairo',
        fontSize: 18,
        width:150
        
      },
      iconButton: {
        background: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
      },
       editIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
      },
      deleteIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
        margin:5
      },
      addIcon: {
        backgroundColor: COLOR.secondary,
        '&:hover': {
          background: COLOR.secondary,
       },
        fontFamily: 'Cairo',
        fontSize:16,
        
      },
      title: {
        fontFamily: 'Cairo',
        fontSize : 25,
        textAlign: 'center'
        
      },
      button: {
        background: COLOR.secondary,
  margin: '5px',
  fontFamily: 'Cairo',
  fontSize : 16,
  color: '#000',
  '&:hover': {
    background: COLOR.secondary,
 },
      },
  });

  
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });




class Address extends Component {

  constructor(props) {
    super(props);
    this.TablePaginationActions = this.TablePaginationActions.bind(this)
    this.state = {
        page: 0,
        rowsPerPage: 5,
        open: false,
        name: null,
        username: null,
        password: null,
        mobile: null,
        nameEdit: null,
        usernameEdit: null,
        passwordEdit: null,
        cat: null,
        adminEdit: 0,
        admin: 0,
        addressIdEdit: null,
        addressIdDelete: null,
        openDelete: false,
        edit: false,
        editSuccess: false,
        firstDate: null,
        secondDate: null,
        ...props   
    };
  }

   TablePaginationActions(props) {
    const { classes } = this.props;
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };
  
    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    function handleClickOpen() {
      this.setState({
        open : true
      })
    }
  
    function handleClose() {
      this.setState({
        open : false
      })
    }
    

    function handleDeleteOpen() {
      this.setState({
        openDelete : true
      })
    }
  



    return (
      <div className={classes.pageControls}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
 /* ------------------------------EDIT FUNCTIONS------------------------------ */


 handleNameEdit = event => {
  this.setState({
    nameEdit : event.target.value
  })
};
handleMobileEdit = event => {
  this.setState({
    mobileEdit : event.target.value
  })
};

handleMobile = event => {
  this.setState({
    mobile : event.target.value.trim(),
    firstDate: null,
    secondDate: null
  })
};

handleFirstDate = event => {
  this.setState({
    firstDate : event.target.value,
    mobile: null
  })
};

handleSecondDate = event => {
  this.setState({
    secondDate : event.target.value,
    mobile: null
  })
};
handleSelectEdit = event => {
  this.setState({
    catId : event.target.value
  })
};



 
  addressEditId = (id) => {
    
    let addressIdEdit = id;
    this.props.fetcheditaddress({ addressIdEdit });  
    this.setState({
      edit: true,
      addressIdEdit: id
    })
  }

  editaddress = () => {
    if (this.state.nameEdit !== null && this.state.nameEdit !== ''  && this.state.mobileEdit !== null  && this.state.mobileEdit !== '' ) 
     {
      let userId = cookie.load('id');
    const { nameEdit, mobileEdit, catId, addressId} = this.state
    this.props.editaddress({ nameEdit, mobileEdit, catId, addressId, userId });
     } else if (this.state.nameEdit === null | this.state.nameEdit === ''  |this.state.mobileEdit === null | this.state.mobileEdit === '' ){
      alert(
          'الرجاء إدخال جميع البيانات')
  }
  }

  addressDeleteId = (id) => {
    this.setState({
      addressIdDelete : id,
      openDelete: true,
    })
  }


deleteaddress = () => {
      const { addressIdDelete } = this.state
      this.props.deleteaddress({ addressIdDelete });
}

search = () => {

  const { mobile , firstDate, secondDate} = this.state
  this.props.searchAddress({ mobile , firstDate, secondDate });

}

componentDidMount(){
   this.props.resetaddresses();
    this.props.resetdeleteaddress();
    this.props.reseteditaddress(); 
    this.props.resetsearch();
    this.props.fetchaddresses();
    this.props.fetchcategories();
}


componentWillReceiveProps(props, nextProps) {
if(props.data !== undefined)
{
  this.setState({
    data : props.data
  })
} 
if(props.search !== null && props.search.length > 0 )
{
  this.setState({
    data : props.search
  })
} 

// if(props.date !== undefined && props.date.length !== 0 && props.date.length != null )

if(props.editData)
{
  this.setState({
    addressId: props.editData.id,
    nameEdit: props.editData.name,
    mobileEdit: props.editData.mobile,
    lat: props.editData.lat,
    lng: props.editData.lng,
    catId: props.editData.cat_id,

  })
} 

if(props.cats)
{
  this.setState({
    cats: props.cats,
  })
}  
 if( props.deleteLoading !== false) 
  {
    this.props.resetaddresses(); 
    this.props.fetchaddresses(); 
    this.setState({
      openDelete: false,
    })


}


if(props.editLoading !== false)
{
  this.props.resetaddresses(); 
  this.props.fetchaddresses();
  const { mobile } = this.state
  this.props.searchAddress({ mobile });


    this.setState({
    edit: false,
  })
}
  

if(props.searchloading !== false)
{
  var first = new Date(this.state.firstDate)
  var second = new Date(this.state.secondDate)
  if(first <= second)
  {
    const { mobile, firstDate, secondDate } = this.state
    this.props.searchAddress({ mobile, firstDate, secondDate });
  } else {
    this.props.fetchaddresses();
  }
  
}

}
  
  render() {
    const { classes } = this.props;

    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.data.length - this.state.page * this.state.rowsPerPage);

    const handleChangePage = (event, newPage) => {
         this.setState({
             page : newPage
         })
    };
  
    const handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page : 0
        })
    };

    function handleClickOpen() {
      this.setState({
        open : true
      })
    }
  
    function handleClose() {
      this.setState({
        open : false
      })
    }
    
    function handleCloseEdit() {
      this.setState({
        edit : false
      })
    }

    function handleDeleteOpen() {
      this.setState({
        openDelete : true
      })
    }
  
    function handleDeleteClose() {
      this.setState({
        openDelete : false
      })
    }
    console.log(this.state.mobile)
    console.log(this.state.firstDate)
    console.log(this.state.secondDate)

    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
      {this.props.data !== undefined && this.props.dateloading!== true && this.props.deleteLoading !== true && this.props.editLoading!== true && this.props.searchloading !== true?

      <Grow in={true}
        {... { timeout: 2000 }}
        >
        
      <Paper >
      <Grid container  
      alignItems="center"
          justify="center" 
          spacing={1} 
          style={{padding: 10 }}
          >  
                    <Grid item>
            <TextField id="input-with-icon-grid" label=" البحث عن عنوان برقم الموبايل" 
            defaultValue={this.state.mobile}
                  onChange={this.handleMobile}
            style={{width: 300 
            }}
            inputProps={{
              style: {fontSize: 20 }  
            }}
            InputLabelProps={{style: {fontSize: 20 ,fontFamily: 'Cairo'  }}}

            />
            </Grid>
            <TextField id="input-with-icon-grid" 
            label="من" 
            defaultValue={this.state.firstDate}
            onChange={this.handleFirstDate}
                  type="date"
            style={{width: 300, padding: 10}}
            inputProps={{
              style: {fontSize: 20 }  ,
            }}
            InputLabelProps={{ shrink: true, style: {fontSize: 17 ,fontFamily: 'Cairo'  }}}

            />
            <TextField id="input-with-icon-grid"
            label="الي"
            defaultValue={this.state.secondDate}
            onChange={this.handleSecondDate}
                  type="date"
            style={{width: 300 , padding: 10}}
            inputProps={{
              style: {fontSize: 20 }  
            }}
            InputLabelProps={{ shrink: true, style: {fontSize: 17 ,fontFamily: 'Cairo'  }}}

            />
          <Grid item>
          <Button className={classes.iconButton} aria-label="search" onClick={this.search.bind(this)}>
        <SearchIcon fontSize='medium'  />
      </Button>
          </Grid>
        </Grid>
        <div className={classes.root}>
        <Table aria-label="custom pagination table"  >
        <TableHead className="table-header" >
        <TableRow style={{height:40}} >
        <TableCell style={{fontFamily:'Cairo' , fontSize: 16}}>الإسم</TableCell>
                <TableCell style={{fontFamily:'Cairo' , fontSize: 16, width:100}}> الموبايل</TableCell>
                <TableCell style={{fontFamily:'Cairo' , fontSize: 16, width:100}}>العنوان</TableCell>
                <TableCell style={{fontFamily:'Cairo' , fontSize: 16}}>التـاريخ</TableCell>
                <TableCell style={{fontFamily:'Cairo',fontSize: 16, width:120}}>خط الطول</TableCell>
                <TableCell style={{fontFamily:'Cairo',fontSize: 16, width:120}}>خط العرض</TableCell>
                <TableCell style={{fontFamily:'Cairo',fontSize: 16}}>الفئة</TableCell>
                <TableCell style={{fontFamily:'Cairo',fontSize: 16}}>الإجراء</TableCell>

              </TableRow>
        </TableHead>
        
        <TableBody>
        {(this.state.rowsPerPage > 0
              ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
              : this.state.rows
            ).map(row => (
            <TableRow key={row.id} >
            <TableCell  style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.name}
              </TableCell>
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}}>
                {row.mobile}
              </TableCell>
              <TableCell style={{ backgroundColor:COLOR.light, fontFamily:'Cairo' , fontSize: 16}}>{row.address}</TableCell>
              <TableCell style={{fontFamily:'Cairo' , fontSize: 16}}>{row.date}</TableCell>
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}} >{row.lat}</TableCell>
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}} >{row.lng}</TableCell>
              <TableCell  style={{fontFamily:'Cairo',fontSize: 16}} >{row.cat_name}</TableCell>
                <TableCell style={{padding : 0}}>
                <Button className={classes.editIcon} onClick={()=> this.addressEditId(row.id)}>
        <Edit fontSize='small'  />
      </Button>
      <Button className={classes.deleteIcon} onClick={()=> this.addressDeleteId(row.id)} >
        <Delete fontSize='small'  />
      </Button>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={8}
                 count={this.state.data.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={this.TablePaginationActions}
                labelRowsPerPage={<div className={classes.pagination}>عدد العناوين</div>}
                labelDisplayedRows={({ from, to, count }) => <div className={classes.pagination}> {from} إلي {to} من مجموع {count} عناوين  </div>}              />
            </TableRow>
            </TableFooter>
        </Table>
        </div>
    </Paper>
    </Grow> 
        : 
        <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{height: '100vh'}}
            >
        <CircularProgress style={{color: COLOR.primary}} size={50} /> 
        </Grid>
      }
      {/* ---------------------EDIT---------------------- */}
      <Dialog open={this.state.edit} onClose={handleCloseEdit.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>تعديل بيانات العنوان</span></DialogTitle>
        <DialogContent>
                       <div>
                         {this.props.editData !== undefined ?
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >

         <TextField
        defaultValue={this.state.nameEdit}
      onChange={this.handleNameEdit}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="name"
        label="الإسم"
        margin="normal"
      />
        <TextField
      defaultValue={this.state.mobileEdit}
      onChange={this.handleMobileEdit}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      
        id="username"
        label="رقم الموبايل"
        margin="normal"
      />
       <TextField
       disabled
      defaultValue={this.state.lat}
      style={{marginBottom: 0 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="number"
        id="password"
        label="خط الطول"
        margin="normal"
      />
        <TextField
        disabled
       defaultValue={this.state.lng}
      style={{marginBottom: 15 , width:200}}
      inputProps={{
        style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
      }}
      InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}
      type="number"
        id="mobile"
        label="خط العرض"
        margin="normal"
      />
      <FormControl style={{marginBottom: 0, width:200}}>
        <InputLabel shrink style={{fontFamily: 'Cairo'}}>
          الفئـة
        </InputLabel>
        <NativeSelect
        defaultValue={this.state.catId}
          onChange={this.handleSelectEdit}
          inputProps={{
            style: { fontFamily: 'Cairo'}
          }}
        >
                {this.state.cats.map(row => (
          <option value={row.cat_id}>{row.cat_name}</option>

          ))}
        </NativeSelect>
      </FormControl>
      <DialogActions>

          <Button onClick={handleCloseEdit.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.editLoading !== true ?  <Button  color="primary" className={classes.button} onClick={this.editaddress.bind(this)} >
            تعديل  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.editaddress.bind(this)} >
            نعديل  
          </Button>  }
         { this.props.editLoading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 

        </Grid>
        :
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '100%'}}
      >
        <CircularProgress style={{color: COLOR.primary}} size={30} />  
        </Grid>
      }
        </div>
 
        </DialogContent>
        
       
        </div>
      </Dialog>

      
{/*--------------------------------------------------*/}

<Dialog open={this.state.openDelete} onClose={handleDeleteClose.bind(this)} aria-labelledby="form-dialog-title" >
          <div>
        <DialogTitle ><span className={classes.title}>تأكيد حذف العنوان</span></DialogTitle>
        <DialogContent>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{minHeight: '100%'}}
        >
      <DialogActions>
          <Button onClick={handleDeleteClose.bind(this)}  className={classes.button} elevation="1">
            الغـاء
          </Button>
          { this.props.deleteLoading !== true ?  <Button  color="primary" className={classes.button} onClick={this.deleteaddress.bind(this)} >
            تأكيد  
          </Button> : <Button  disabled color="primary" className={classes.button} onClick={this.deleteaddress.bind(this)} >
          تأكيد  
          </Button>  }
         { this.props.deleteLoading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }

        </DialogActions> 
        </Grid>
        </DialogContent>
        
       
        </div>
      </Dialog>
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}
  
const mapStateToProps = state => {
  return {
      data: state.fetchaddresses.data,
      deleteLoading: state.deleteaddress.deleteLoading,
      deleteMessage: state.deleteaddress.deleteMessage,
      deleteSuccess: state.deleteaddress.deleteSuccess,
      reset: state.deleteaddress.reset,
      editData: state.fetcheditaddress.data,
      editSuccess: state.editaddress.success,
      editLoading: state.editaddress.loading,
      editMessage: state.editaddress.message,
      cats: state.fetchcategories.data,
      search: state.searchAddress.data,
      searchloading: state.searchAddress.loading,
      date: state.searchdate.data,
      dateloading: state.searchdate.loading,
  }
}


export default withStyles(styles)(connect(mapStateToProps, {fetchaddresses , resetaddresses , deleteaddress, resetdeleteaddress , fetcheditaddress ,editaddress, reseteditaddress, fetchcategories, searchAddress, resetsearch, searchdate})(Address));
