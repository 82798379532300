export const SEND_ATTEMPT = 'send_attempt';
export const SEND_SUCCESS = 'send_success';
export const SEND_FAILED = 'send_failed';

export const CREATEUSER_ATTEMPT = 'createuser_attempt';
export const CREATEUSER_SUCCESS = 'createuser_success';
export const CREATEUSER_FAILED = 'createuser_failed';

export const FETCHUSERS = 'fetchusers';
export const RESET_USERS = 'reset_users';

export const DELETE_USER_SUCCESS = 'delete_user_success';
export const DELETE_USER_FAILED = 'delete_user_failed';
export const DELETE_USER_ATTEMPT= 'delete_user_attempt';
export const RESET_DELETE_USER= 'reset_delete_user';

export const FETCH_EDIT_USER= 'fetch_edit_user';

export const EDIT_USER_ATTEMPT= 'edit_user_attempt';
export const EDIT_USER_SUCCESS = 'edit_user_success';
export const EDIT_USER_FAILED = 'edit_user_failed';
export const RESET_EDIT_USER = 'reset_edit_user';


// Categories

export const CREATECAT_ATTEMPT = 'createcat_attempt';
export const CREATECAT_SUCCESS = 'createcat_success';
export const CREATECAT_FAILED = 'createcat_failed';

export const FETCHCATS = 'fetchcats';
export const RESET_CATS = 'reset_cats';

export const DELETE_CAT_SUCCESS = 'delete_cat_success';
export const DELETE_CAT_FAILED = 'delete_cat_failed';
export const DELETE_CAT_ATTEMPT= 'delete_cat_attempt';
export const RESET_DELETE_CAT= 'reset_delete_cat';

export const FETCH_EDIT_CAT= 'fetch_edit_cat';

export const EDIT_CAT_ATTEMPT= 'edit_cat_attempt';
export const EDIT_CAT_SUCCESS = 'edit_user_cat';
export const EDIT_CAT_FAILED = 'edit_user_cat';
export const RESET_EDIT_CAT = 'reset_edit_cat';


// Addresses


export const FETCH_ADDRESSES = 'fetch_addresses';
export const RESET_ADDRESSES = 'reset_addresses';
export const FETCH_ADDRESSES_ATTEMPT = 'fetch_addresses_attempt';


export const DELETE_ADDRESS_SUCCESS = 'delete_address_success';
export const DELETE_ADDRESS_FAILED = 'delete_address_failed';
export const DELETE_ADDRESS_ATTEMPT= 'delete_address_attempt';
export const RESET_DELETE_ADDRESS= 'reset_delete_address';

export const FETCH_EDIT_ADDRESS= 'fetch_edit_address';

export const EDIT_ADDRESS_ATTEMPT= 'edit_address_attempt';
export const EDIT_ADDRESS_SUCCESS = 'edit_user_address';
export const EDIT_ADDRESS_FAILED = 'edit_user_address';
export const RESET_EDIT_ADDRESS = 'reset_edit_address';

export const FETCH_CATEGORIES = 'fetch_categories';

export const CREATE_ADDRESS_ATTEMPT= 'create_address_attempt';
export const CREATE_ADDRESS_SUCCESS = 'create_user_address';
export const CREATE_ADDRESS_FAILED = 'create_user_address';

export const MAIN_DATA = 'main_data';
export const SEARCH_ATTEMPT = 'search_attempt';
export const SEARCH_SUCCESS = 'search_success';
export const SEARCH_FAILED = 'search_failed';
export const RESET_SEARCH = 'reset_search';

export const DATE_ATTEMPT = 'date_attempt';
export const DATE_SUCCESS = 'date_success';
export const DATE_FAILED = 'date_failed';
export const RESET_DATE = 'reset_date';


//EDITOR

export const FETCH_ADDRESSES_EDITOR = 'fetch_addresses_editor';
export const FETCH_ADDRESSES_ATTEMPT_EDITOR = 'fetch_addresses_attempt_editor';

export const FETCH_CATEGORY_EDITOR = 'fetch_category_editor';

export const EDIT_ADDRESS_ATTEMPT_EDITOR = 'EDIT_ADDRESS_ATTEMPT_EDITOR';
export const EDIT_ADDRESS_SUCCESS_EDITOR = 'EDIT_ADDRESS_SUCCESS_EDITOR';
export const EDIT_ADDRESS_FAILED_EDITOR = 'EDIT_ADDRESS_FAILED_EDITOR';
export const RESET_EDIT_ADDRESS_EDITOR = 'RESET_EDIT_ADDRESS_EDITOR';

export const SEARCH_ATTEMPT_EDITOR = 'SEARCH_ATTEMPT_EDITOR';
export const SEARCH_SUCCESS_EDITOR = 'SEARCH_SUCCESS_EDITOR';
export const SEARCH_FAILED_EDITOR = 'SEARCH_FAILED_EDITOR';
export const RESET_SEARCH_EDITOR = 'RESET_SEARCH_EDITOR';



///

export const SEARCH_ADDRESS_ATTEMPT_MAP= 'search_address_attempt_mapp';
export const SEARCH_ADDRESS_SUCCESS_MAP = 'search_address_success_mapp';
export const SEARCH_ADDRESS_FAILED_MAP = 'search_address_failed_mapp';

export const FETCH_CATEGORIES_MAP = 'fetch_categories_mapp';
export const FETCH_CATEGORIES_ATTEMPT_MAP = 'fetch_categories_attempt_mapp';

export const FETCH_ADDRESSES_MAP = 'fetch_addresses_mapp';
export const FETCH_ADDRESSES_ATTEMP_MAP = 'fetch_addresses_attempt_mapp';

export const FETCH_PERMISSIONS = 'fetch_permissions';
export const FETCH_PERMISSIONS_ATTEMPT = 'fetch_permissions_attempt';

export const MAIN_DATA_EDITOR = 'MAIN_DATA_EDITOR';

export const SEARCH_ADDRESS_ATTEMPT_EDITOR= 'SEARCH_ADDRESS_ATTEMPT_EDITOR';
export const SEARCH_ADDRESS_SUCCESS_EDITOR = 'SEARCH_ADDRESS_SUCCESS_EDITOR';
export const SEARCH_ADDRESS_FAILED_EDITOR = 'SEARCH_ADDRESS_FAILED_EDITOR';

export const FETCH_ADDRESSES_ATTEMPT_EDITOR_MAP = 'FETCH_ADDRESSES_ATTEMPT_EDITOR_MAP';
export const FETCH_ADDRESSES_EDITOR_MAP = 'FETCH_ADDRESSES_EDITOR_MAP';