import { EDIT_USER_ATTEMPT, EDIT_USER_SUCCESS, EDIT_USER_FAILED , RESET_EDIT_USER} from '../../actions/types'
const INITIAL_STATE = {
    loading: false,
    message: null ,
    success: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EDIT_USER_ATTEMPT:
            return { ...state, loading: true , success: false}
        case EDIT_USER_SUCCESS:
            return { ...state, loading: false, message: action.message, success: action.success  }
        case EDIT_USER_FAILED:
             return { ...state, loading: false, message: action.message, success: action.success }
        case RESET_EDIT_USER:
             return { ...state, loading: false , success: false, message: null }
        default:
            return state;
    }
}