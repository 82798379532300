import { SEND_ATTEMPT, SEND_SUCCESS, SEND_FAILED} from '../actions/types'
const INITIAL_STATE = {
    loading: false,
    id: null,
    name: null,
    admin: null,
    default_cat: null,
    username: null,
    error: '',
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_ATTEMPT:
            return { ...state, loading: true, success: true }
        case SEND_SUCCESS:
            return { ...state, loading: false, id: action.id ,name: action.name , admin: action.admin , default_cat: action.default_cat, username: action.username }
        case SEND_FAILED:
            return { ...state, loading: false, error: action.error}
            default:
            return state;
    }
}