import React, { Component  } from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { MuiThemeProvider, createMuiTheme ,withStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchcategories, sendData  } from './redux/actions';
import { connect } from "react-redux";
import  QRCode  from 'qrcode.react';
import Beenhere from '@material-ui/icons/Beenhere';
import COLOR from './color';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import MyLocation from '@material-ui/icons/MyLocation';

import './styles.css';
import './App.css';


const theme = createMuiTheme({
  direction: "rtl",
  palette: {
  },
});

const styles = theme => ({
  divStyle: {
    background:  COLOR.primaryG,
    margin: '5px',
    fontFamily: 'Cairo',
    fontSize : 20,
    color: '#000',
  },
  input: {
    fontFamily: 'Cairo',
    textAlign: 'left',
    marginBottom: '20px',
    },
  button: {
    background: COLOR.primaryG,
    margin: '5px',
    fontFamily: 'Cairo',
    fontSize : 13,
    color: '#000'
    },
  title: {
    fontFamily: 'Cairo',
    fontSize : 25,
    textAlign: 'center'
      
    },
});


const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


class AddAddress extends Component  {
  constructor(props) {
    super(props);
    this.state = {
        name: '',
        mobile: '',
        lat:  32.439064,
        lng: 14.550571,
        open: false,
        successAlert: false,
        locaion: false,
        moveMarker: false,
        icon : "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
        ...props
    };
}

componentDidMount(){
  this.props.fetchcategories();

}


componentWillReceiveProps(props, nextProps) {
  {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
      (position) => {
        if(this.state.moveMarker !== true)
        {
          this.setState({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              location: true
          });
      }},
      (error) =>
      this.setState({
          location: false,
          error: error
      })
      ,
    
    
      { enableHighAccuracy: true, timeout: 50000 },
    );
    }
  }
  if(props.cats)
  {
    this.setState({
      cats: props.cats,
      catId: props.cats[0].cat_id
    })
  }  
    
  if(props.success)
  {
      this.setState({
        success: props.success,     
      })
  }

  if(props.message === 'failed' && this.state.name !== null && this.state.name !== ''  && this.state.mobile !== null  && this.state.mobile !== ''  )
  {
    this.setState({
      successAlert: true,     
    })
  } else if(props.message === 'success')
  {
    this.setState({
      successAlert: false,     
    })
  }
  }

  onMarkerClick = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
  this.setState({
    lat: lat,
    lng: lng,
    
  })
  }



handleNameOnChange = event => {
  this.setState({
    name : event.target.value
  })
};

handleMobileOnChange = event => {
  this.setState({
    mobile : event.target.value
  })
};

sendData = () => {
  if (this.state.name !== null && this.state.name !== ''  && this.state.mobile !== null  && this.state.mobile !== '' ) 
   {
    let userId = 0;
  const { name, mobile, lat, lng , catId } = this.state
  this.props.sendData({ name, mobile, lat, lng, catId, userId });
   } else if (this.state.name === null | this.state.name === ''  |this.state.mobile === null | this.state.mobile === '' ){
    alert(
        'الرجاء إدخال جميع البيانات')

}
}


handleName = event => {
  this.setState({
    name : event.target.value
  })
};

handleMobile = event => {
  this.setState({
    mobile : event.target.value
  })
};

handleSelect = event => {
  this.setState({
    catId : event.target.value
  })
};

  render() {
    const { classes } = this.props;


    function handleClickOpen() {
      this.setState({
        open : true
      })
    }

    function handleBack() {
      this.setState({
        open : false,
        success : false,
        successAlert : false,
      })
    }
  
    function handleClose() {
      this.setState({
        open : false
      })
    }
   console.log(this.state.moveMarker)
  return (
    
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}> 
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={this.state.successAlert}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
       <SnackbarContent style={{backgroundColor: theme.palette.error.dark, fontFamily: 'Cairo'}} 
        message={<div>
          <span style={{textAlign: 'center', fontFamily: 'Cairo', color:'#fff', fontSize:16}}>رقم الهاتف مرتبط بعنوان آخر</span>

        </div>}>
        </SnackbarContent>
      </Snackbar>
    { (this.state.location) ? 
    <div className="App" dir="rtl">
     <div className = "over">
    <Button variant="contained" color="primary" size="medium" className={classes.divStyle} onClick={handleClickOpen.bind(this)}> 
       التسجيل في العنوان
    </Button>
    
    </div>
    <div className = "overLeft">
    <Button variant="contained" color="primary" size="large" className={classes.divStyle} onClick={() => this.setState({ moveMarker: false , icon : "http://maps.google.com/mapfiles/kml/paddle/red-circle.png"})}> 
       <MyLocation />
    </Button>
    
    </div>
      <Map google={this.props.google}   zoom={15} center={{
            lat: this.state.lat,
            lng: this.state.lng
          }}
          initialCenter={{
            lat: this.state.lat,
            lng: this.state.lng
          }}
          fullscreenControl={false}
          rotateControl={false}
          panControl={false}
          streetViewControl={false}
          scaleControl={false}
          zoomControl={false}
          mapTypeControl={true}
          style={{width: '80vw'}}
          >

<Marker 
style={{backgroundColor: '#000'}}
onDragend={(t, map, coord) => this.onMarkerClick(coord)}
onClick={() => this.setState({ moveMarker: true, icon : "http://maps.google.com/mapfiles/kml/paddle/orange-circle.png"})}
options={{ icon: { url: this.state.icon , scaledSize: { width: 50, height: 50 } } }}
        name={'Current location'} 
        draggable={this.state.moveMarker}
         position={{ lat: this.state.lat , lng:  this.state.lng }}/>

</Map>


<Dialog open={this.state.open} onClose={handleClose.bind(this)} aria-labelledby="form-dialog-title" dir="rtl" >
{ this.props.data === undefined &&  this.state.success != true? 
  <div>
   
  <DialogTitle ><span className={classes.title}>إضـافة عنوان جديد</span></DialogTitle>
  <DialogContent>
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{minHeight: '100%'}}
  >
  <TextField
        onChange={this.handleName}
style={{marginBottom: 0 , width:200}}
inputProps={{
  style: {fontSize: 20 , fontFamily: 'Cairo' , color:'#000' , borderBottomColor: '#000 !important'}  
}}
InputLabelProps={{style: {fontSize: 15 ,fontFamily: 'Cairo' , color:'#4c4a47' }}}

  id="standard-name"
  label="الإسم الثلاثي"
  margin="normal"
/>
  <TextField
onChange={this.handleMobile}
style={{marginBottom: 15, width:200}}
inputProps={{
  style: {fontSize: 20 , color:'#000' , borderBottomColor: '#000 !important'}  
}}
InputLabelProps={{style: {fontSize: 15 , fontFamily: 'Cairo' , color:'#4c4a47' }}}
type="number"
id="standard-onwan-input"
label="رقم الهاتف"
  margin="normal"
/>
{ /* <TextField
disabled
      defaultValue={this.state.lng}
style={{marginBottom: 15, width:200}}
inputProps={{
  style: {fontSize: 20 , color:'#000' , borderBottomColor: '#000 !important'}  
}}
InputLabelProps={{style: {fontSize: 15 , fontFamily: 'Cairo' , color:'#4c4a47' }}}
type="number"
id="standard-onwan-input"
label="خط الطول"
  margin="normal"
/>
<TextField
disabled
defaultValue={this.state.lat}
style={{marginBottom: 15, width:200}}
inputProps={{
  style: {fontSize: 20 , color:'#000' , borderBottomColor: '#000 !important'}  
}}
InputLabelProps={{style: {fontSize: 15 , fontFamily: 'Cairo' , color:'#4c4a47' }}}
type="number"
id="standard-onwan-input"
label="خط العرض"
  margin="normal"
/> */ }

 <FormControl style={{marginBottom: 0, width:200}}>
  <InputLabel shrink style={{fontFamily: 'Cairo'}}>
    الفئـة
  </InputLabel>
  <NativeSelect
    onChange={this.handleSelect}
    inputProps={{
      style: { fontFamily: 'Cairo'}
    }}
  >
          {this.state.cats.map(row => (
    <option value={row.cat_id}>{row.cat_name}</option>

    ))}
  </NativeSelect>
</FormControl>
<DialogActions>
{ this.props.loading !== true ? <Button  onClick={this.sendData.bind(this)} color="primary" className={classes.button}>
      تسجيل  
    </Button> :
    <Button  disabled onClick={this.sendData.bind(this)} color="primary" className={classes.button}>
      تسجيل  
    </Button>
    }
             { this.props.loading !== false ? <CircularProgress style={{color: COLOR.primary}} size={30} />  : null }
    <Button onClick={handleClose.bind(this)} color="primary" className={classes.button}>
      الغـاء
    </Button>
    
  </DialogActions> 
  </Grid>
  </DialogContent>
  
 
  </div>
         : <div style={{ textAlign : 'center' , fontFamily: 'Cairo ' ,padding:10 , background: '#fff'}}>            <Beenhere style={{ fontSize: 100 , color: '#00b13a'}}  />
         {this.props.address !== null ? <h1 style={{ fontFamily: 'Cairo ' , fontSize:15 , color:'#000' }}>تم تسجيلكم في العنوان بنجاح <br /> العنوان الخاص بك هو</h1> : this.props.message } <div style={{ fontFamily: 'Tahoma', borderRadius: 6 ,  background: 'linear-gradient(160deg,#ebf2ec,#eaf1eb,#e9f0ea,#e8efe9,#e6eee7,#e5ede6,#e4ece5,#e3ebe4)', padding : 0 , textAlign : 'center' , fontSize:'7vh', color:'#000' }}>{this.props.address}</div>
         <div style={{marginTop:10 }}> 
           <QRCode
         value={String(this.props.onwan)}
         size={200}
         level={"H"}
         includeMargin={true}
       /></div>
        <Button variant="contained" color="primary" size="medium" className={classes.divStyle} onClick={handleBack.bind(this)}> 
        تسجيل عنوان أخر 
    </Button>
       </div>
         }
      </Dialog>
       

    </div>
    : this.props.success === true ? <div style={{ textAlign : 'center' , backgroundColor: 'red'}}></div> 
    : <div style={{ textAlign : 'center' , marginTop: '80%' , fontFamily: 'Cairo '}}><p style={{ textAlign : 'center' , fontFamily: 'Cairo ' }}></p>الرجاء تفعيل بيانات الموقع وتحديث الصفحة
    <Button variant="contained" color="secondary" size="medium" className={classes.divStyle} onClick={() => window.location.reload()}>
       تحديث الصفحة 
    </Button>
    </div>   }
    </MuiThemeProvider>
    </StylesProvider> 
  );
}
}


 const mapStateToProps = state => {
  return {
      cats: state.fetchcategories.data,
      message: state.addaddress.message,
      address: state.addaddress.address,
      success: state.addaddress.success,
      loading: state.addaddress.loading

  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyDFdoN0NF6ue3dLmPRE8Id8XMVCZ1Xr434")
})(connect(mapStateToProps, { sendData, fetchcategories})((withStyles(styles))(AddAddress)));