
import { DATE_ATTEMPT, DATE_SUCCESS, DATE_FAILED , RESET_DATE} from '../../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false,
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DATE_ATTEMPT:
            return { ...state, loading: true }
        case DATE_SUCCESS:
            return { ...state, data: action.data,loading: false  }
        case DATE_FAILED:
             return { ...state,data: action.data, loading: false }
        case RESET_DATE:
             return { ...state, loading: false , data: [] }
        default:
            return state;
    }
}