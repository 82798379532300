import { FETCHUSERS } from '../types'
import axios from 'axios';
export const fetchusers = () => {

    return (dispatch) => {
        dispatch({ type: FETCHUSERS });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/users/fetchUsers.php')
             .then((resp) => dispatch({ type: FETCHUSERS, data: resp.data }))
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
