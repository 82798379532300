import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Admin from '../src/Admin';
import Map from './Mapp';
import Editor from '../src/Editor';
import User from './user'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import appStore from './redux/store';
import cookie from 'react-cookies';

const store = appStore();
ReactDOM.render(


  <Provider store={store}>
    <Router>
    <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/user">
            <User />
          </Route>
          <Route>
            <Editor />
          </Route>
        </Switch>
        </Router>
        </Provider>, document.getElementById('root')

  
  );
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
