import { FETCH_ADDRESSES_EDITOR, FETCH_ADDRESSES_ATTEMPT_EDITOR } from '../../types'
import axios from 'axios';
export const fetchaddresseseditor = ({ catId , userId })  => {

    return (dispatch) => {
        dispatch({ type: FETCH_ADDRESSES_ATTEMPT_EDITOR });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/addresses/editor/fetchAddress.php' ,  { catId , userId})
             .then((resp) => dispatch({ type: FETCH_ADDRESSES_EDITOR, data: resp.data }))
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}
