import { SEARCH_ADDRESS_ATTEMPT_EDITOR, SEARCH_ADDRESS_SUCCESS_EDITOR, SEARCH_ADDRESS_FAILED_EDITOR } from '../../../actions/types'
const INITIAL_STATE = {
    data: [],
    loading: false,
    
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_ADDRESS_ATTEMPT_EDITOR:
            return { ...state, loading: true }
        case SEARCH_ADDRESS_SUCCESS_EDITOR:
            return { ...state, loading: false,  data: action.data  }
        case SEARCH_ADDRESS_FAILED_EDITOR:
             return { ...state, loading: false, data: action.data }
        default:
            return state;
    }
}