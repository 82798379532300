import { CREATEUSER_ATTEMPT, CREATEUSER_SUCCESS, CREATEUSER_FAILED, RESET_USERS} from '../../actions/types'
const INITIAL_STATE = {
    loading: false,
    success: false,
    message: null,
    error: false,
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATEUSER_ATTEMPT:
            return { ...state, loading: true }
        case CREATEUSER_SUCCESS:
            return { ...state, loading: false, success: true, message: action.message  }
        case CREATEUSER_FAILED:
             return { ...state, loading: false, success: true,message: action.message ,error: true }
        case RESET_USERS:
             return { ...state, loading: false, success: false,message: null ,error: false }
       default:
            return state;
    }
}