import { DELETE_ADDRESS_SUCCESS, DELETE_ADDRESS_FAILED,DELETE_ADDRESS_ATTEMPT, RESET_DELETE_ADDRESS} from '../../actions/types'
const INITIAL_STATE = {
    deleteMessage: null,
    deleteLoading: false,
    deleteSuccess: false,
    reset: false
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_ADDRESS_ATTEMPT:
            return { ...state, deleteMessage: action.message, deleteLoading: true, deleteSuccess: false}
        case DELETE_ADDRESS_SUCCESS:
            return { ...state, deleteMessage: action.message,  deleteLoading: false , deleteSuccess: true}
        case DELETE_ADDRESS_FAILED:
            return { ...state, deleteMessage: action.message , deleteLoading: false , deleteSuccess: true}
        case RESET_DELETE_ADDRESS:
            return { ...state, deleteMessage: null, deleteLoading: false, deleteSuccess: false, reset: true }
       default:
            return state;
    }
}