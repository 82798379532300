import React , {Component ,  useState } from 'react';
import { makeStyles,fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';
import COLOR from './color';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import CountUp from 'react-countup';
import { maindata } from './redux/actions';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';


const theme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: grey,
  },
});


const styles = theme => ({
  root: {

  },
  mainCard: {
    display: 'flex',
    width:'100%'
  },
  mainInfo : {
    [theme.breakpoints.up('sm')]: {
      padding:10,
    display: 'flex',
    },
    padding:10,
    margin: 0
  },
  mainDetails: {
    display: 'flex',
    flexDirection: 'column',
    width:'70%'

  },
  mainContent: {
    paddingRight: theme.spacing(3),
    flex: '1 0 auto',
    backgroundColor: COLOR.light,
    
    
  },
  mainCardText: {
    fontFamily: 'Cairo',fontSize:15,
    [theme.breakpoints.up('sm')]: {
      fontSize:20
    },
    
  },
  mainCardNumber: {
    background:COLOR.secondary,
    fontFamily: 'Cairo',
    fontSize:25,
    textAlign: 'center',
    width: '30%',
    paddingTop:10,
    [theme.breakpoints.up('sm')]: {
      fontSize:35
    },
    
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width:'33%'
    },
    flexDirection: 'row',
    margin:10,
  },
  info : {
    [theme.breakpoints.up('sm')]: {
      padding:20,
    display: 'flex',
    flexDirection: 'row',
    },
    padding:20,
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  cardContent: {
    paddingRight: theme.spacing(3),
    flex: '1 0 auto',
    backgroundColor: COLOR.light
    
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });



class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      lat: 32.367875,
      lng: 15.093149,
      mobileOpen: false,
      open: false,
      openuser: false,
      opencat: false,
      anchorEl: null,
      ...props
    };
  }

  componentDidMount(){
    this.props.maindata();
  }
  


  render() {
    const { classes } = this.props;
    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
      { this.props.data !== undefined ?
      <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grow in={true}
        {... { timeout: 2000 }}
        >
        <Paper className={classes.mainInfo} elevation="0">
        <Card className={classes.mainCard} >
      <div className={classes.mainDetails}>
        <CardContent className={classes.mainContent}>
          <Typography  className={classes.mainCardText}>
            العناوين المسجـلة اليوم
          </Typography>
        </CardContent>
      </div>
      <Typography color="textSecondary" className={classes.mainCardNumber}>
      <CountUp end={this.props.data.addressesToday} delay={1}/>
          </Typography>
    </Card>
    </Paper>
    </Grow>
        <Grow in={true}
        {... { timeout: 2000 }}
        >
        <Paper className={classes.info} elevation="0">
        <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.cardContent}>
          <Typography component="h5" variant="h5" style={{fontFamily: 'Cairo',}}>
            العنـاوين
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{fontFamily: 'Cairo',fontSize:14}}>
          عدد العناوين المسجلة
          </Typography>
        </CardContent>
      </div>
      <Typography variant="subtitle1" color="textSecondary" style={{background:COLOR.secondary, fontFamily: 'Cairo', fontSize:50, textAlign: 'center'}}>
      <CountUp end={this.props.data.addresses} delay={1}/>
          </Typography>
    </Card>
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.cardContent}>
          <Typography component="h5" variant="h5" style={{fontFamily: 'Cairo',}}>
            الفئـات
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{fontFamily: 'Cairo',fontSize:14}}>
          عدد الفئات المسجلة
          </Typography>
        </CardContent>
      </div>
      <Typography variant="subtitle1" color="textSecondary" style={{background:COLOR.secondary,fontFamily: 'Cairo', fontSize:50, textAlign: 'center',}}>
      <CountUp end={this.props.data.categories} delay={1}/>
          </Typography>
    </Card>
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.cardContent}>
          <Typography component="h5" variant="h5" style={{fontFamily: 'Cairo',}}>
            المستخدمين
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{fontFamily: 'Cairo',fontSize:14}}>
            عدد مستخدمي النظام
          </Typography>
        </CardContent>
      </div>
      <Typography variant="subtitle1" color="textSecondary" style={{background:COLOR.secondary,fontFamily: 'Cairo', fontSize:50, textAlign: 'center',}}>
      <CountUp end={this.props.data.users} delay={1}/>
          </Typography>
    </Card>
    </Paper>
    </Grow>
      </main>
    </div>
    :
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{minHeight: '100vh'}}
  >
    <CircularProgress style={{color: COLOR.primary}} size={30} />  
    </Grid>
      }
    </MuiThemeProvider>
    </StylesProvider>
      )
  }
}

const mapStateToProps = state => {
  return {
      data: state.main.data,
  }
}

export default withStyles(styles)(connect(mapStateToProps, { maindata })(Main));
