import { EDIT_USER_ATTEMPT, EDIT_USER_SUCCESS, EDIT_USER_FAILED } from '../types'
import axios from 'axios';
export const edituser = ({ userEditId, nameEdit, usernameEdit, passwordEdit, mobileEdit, adminEdit , catIdEdit }) => {

    return (dispatch) => {
        dispatch({ type: EDIT_USER_ATTEMPT });
        //call the backend
            axios.post('https://zliten.onwan.ly/API/users/editUser.php', { userEditId, nameEdit, usernameEdit, passwordEdit, mobileEdit, adminEdit , catIdEdit })
             .then((resp) => handleResponse(dispatch, resp.data)) 
            /* .then((resp) => console.log(resp.data))*/
            .catch(error => console.error(error));
    }
}

const handleResponse = (dispatch, data) => {    
    if (data.success !== true) {
        onFailed(dispatch, data.message, data.success)
        console.log('Failed')
        console.log(data)
    } else {
        onSuccess(dispatch, data.message, data.success )
        console.log('Success')
        console.log(data)
    }
}
const onSuccess = (dispatch, message, success) => {
    dispatch({ type: EDIT_USER_SUCCESS, message: message , success: success })
}

const onFailed = (dispatch,  message, success) => {
    dispatch({ type: EDIT_USER_FAILED,  message: message , success: success  })
}